import queryString from "query-string";
import { useDebounce } from "~/hooks/useDebounce";
import { useFetcher } from "~/hooks/useFetcher";
import SearchResponse from "~/types/interfaces/catalogResponse";
import useFilterParams from "catalog/hooks/useFilterParams";
import { buildFilterMatrix, INDICES } from "catalog/utils/buildFilterMatrix";
import useCatalogEnabled from "catalog/hooks/useCatalogEnabled";

export const PAGE_SIZE = 20;
const { VITE_CATALOG_API } = import.meta.env;

const useCatalogSearch = <T>(entityType: "table" | "column") => {
  const catalogEnabled = useCatalogEnabled();
  const [params] = useFilterParams();
  const { q: search, page, ttags = [], ctags = [] } = params;
  const debouncedSearch = useDebounce(search);
  const filters = buildFilterMatrix((entityType === "table" ? ttags : ctags) as string[]);

  const from = page ? (page - 1) * PAGE_SIZE : 0;

  const qs = queryString.stringify({
    index: INDICES[entityType],
    q: debouncedSearch,
    query_filter: JSON.stringify(filters),
    from,
    size: PAGE_SIZE,
  });

  return useFetcher<SearchResponse<T>>(
    catalogEnabled ? `${VITE_CATALOG_API}/search/query?${qs}` : null,
    {
      keepPreviousData: true,
    }
  );
};

export default useCatalogSearch;
