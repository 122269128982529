import { useFormContext } from "react-hook-form";
import { Box, Button, Stack, Typography as T, useTheme } from "@mui/material";

const ReviewRow = ({ label, value, error }: { label: string; value: string; error?: string }) => {
  const theme = useTheme();

  return (
    <Box
      alignItems="center"
      display="flex"
      gap={3}
      py={1}
      sx={{ borderBottom: "1px solid #e0e0e7", "&:last-of-type": { borderBottom: "unset" } }}
    >
      <Box minWidth={120}>
        <T fontSize={14} sx={{ opacity: 0.6 }}>
          {`${label}:`}
        </T>
      </Box>
      <Box flex={1}>
        <T fontSize={14}>{value}</T>
      </Box>
      {error && (
        <Box>
          <T fontSize={14} color={theme.palette.attention.main} textAlign="end">
            {error}
          </T>
        </Box>
      )}
    </Box>
  );
};

const ReviewDetails = () => {
  const { formState, watch } = useFormContext();

  const sourceData = watch("source_file_url");

  // TODO: adjust review rows during RWB-1588
  const items = [
    { label: "Source Location", value: "Amazon S3", error: "" },
    {
      label: "Source Data",
      value: sourceData ? sourceData : "(Table name / File name)",
      error: "",
    },
    {
      label: "Destination",
      value: watch("metadata_config.database"),
      error: "",
    },
    { label: "Metadata", value: formState.isDirty ? "Yes" : "No", error: "" },
    { label: "Size", value: "5 GB", error: "" },
  ];
  return (
    <>
      <Stack
        sx={{
          border: "1px solid #e0e0e7",
          borderRadius: 1.5,
          paddingX: 2,
          width: "100%",
        }}
      >
        {items.map((row, i) => (
          <ReviewRow key={i} {...row} />
        ))}
      </Stack>
      <Button type="submit" variant="contained">
        Add Table
      </Button>
    </>
  );
};

export default ReviewDetails;
