import yn from "yn";
import { Navigate, Link as RouterLink } from "react-router-dom";
import { ArrowBackIosNew } from "@mui/icons-material";
import {
  Box,
  Button,
  buttonClasses,
  Container,
  IconButton,
  Skeleton,
  Stack,
  Typography as T,
} from "@mui/material";
import { DatasetCardsIcon, PlusIcon } from "~/assets/icons";
import useAppHeader from "~/contexts/header";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import { TitleBreadcrumbs } from "~/components/AppHeader";
import CardGrid from "~/components/CardGrid";
import PageHeader from "~/components/PageHeader";
import useDatasets from "datasets/hooks/useDatasets";
import CreateDataset from "datasets/components/CreateDataset";
import DatasetCard from "datasets/components/DatasetCard";

const { VITE_DATASETS_ENABLED } = import.meta.env;

const Skeletons = () => (
  <>
    {Array.from({ length: 3 }).map((_, i) => (
      <Skeleton key={i} variant="rounded" sx={{ height: 390, display: "flex" }} />
    ))}
  </>
);

const EmptyDatasets = () => {
  return (
    <Stack alignItems="center" marginTop={13}>
      <T
        component="h2"
        fontSize={24}
        fontWeight={500}
        sx={{
          lineHeight: "32px",
          maxWidth: 380,
          opacity: 0.4,
          textAlign: "center",
        }}
      >
        Your organization has not published any datasets
      </T>
      <DatasetCardsIcon style={{ maxWidth: 520, marginBlock: "28px 36px" }} />
      <CreateDataset
        callToAction={
          <Button
            color="primary"
            variant="contained"
            aria-label="Create dataset"
            startIcon={<PlusIcon />}
            sx={{
              height: "auto",
              px: 1.5,
              py: 1,
              [`& .${buttonClasses.startIcon}`]: {
                marginLeft: "unset",
              },
            }}
          >
            New Dataset
          </Button>
        }
      />
    </Stack>
  );
};

const DatasetList = () => {
  const { data: datasets, isLoading, error } = useDatasets();
  const showEmpty = !isLoading && !error && datasets?.length === 0;

  if (showEmpty) {
    return <EmptyDatasets />;
  }

  return (
    <CardGrid minWidth={300} sx={{ gap: 3, gridTemplateColumns: "repeat(auto-fill, 330px)" }}>
      {isLoading ? (
        <Skeletons />
      ) : (
        datasets?.map((dataset) => <DatasetCard key={dataset.id} {...dataset} />)
      )}
    </CardGrid>
  );
};

const title = "Datasets";

const Datasets = () => {
  useDocumentTitle(title);
  useAppHeader({
    title: (
      <TitleBreadcrumbs
        crumbs={[
          { title: "Data Browser", href: "/data-browser" },
          { title, href: location.pathname },
        ]}
      />
    ),
  });
  const { data: datasets } = useDatasets();

  if (!yn(VITE_DATASETS_ENABLED)) {
    return <Navigate to="/404" replace />;
  }

  return (
    <Box bgcolor="common.dimmedPageBackground" minHeight="100%">
      <Container sx={{ pt: 2, pb: 3 }}>
        <Stack gap={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between" minHeight={40}>
            <Box display="flex" alignItems="center" gap={0.75}>
              <IconButton
                aria-label="Back to Data Browser"
                component={RouterLink}
                to="/data-browser"
                sx={{ borderRadius: "50%", height: 40, width: 40 }}
              >
                <ArrowBackIosNew sx={{ width: 20, height: "auto" }} />
              </IconButton>
              <PageHeader lineHeight={1.8}>Datasets</PageHeader>
            </Box>
            {!!datasets?.length && (
              <CreateDataset
                callToAction={
                  <Button
                    aria-label="Create dataset"
                    startIcon={<PlusIcon />}
                    sx={{
                      backgroundColor: "common.white",
                      height: "auto",
                      px: 1.5,
                      py: 1,
                      [`& .${buttonClasses.startIcon}`]: {
                        marginLeft: "unset",
                      },
                    }}
                  >
                    Add Dataset
                  </Button>
                }
              />
            )}
          </Box>
          <DatasetList />
        </Stack>
      </Container>
    </Box>
  );
};

export default Datasets;
