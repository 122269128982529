import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TextOverflow from "~/components/TextOverflow";
import rumClient from "~/utils/monitoring";
import useDatasets from "datasets/hooks/useDatasets";

const { VITE_CATALOG_API } = import.meta.env;

const DeleteDatasetModal = ({
  id,
  name,
  onClose,
  open,
}: {
  id: string;
  name: string;
  onClose: () => void;
  open: boolean;
}) => {
  const navigate = useNavigate();
  const { mutate } = useDatasets();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");

    try {
      const resp = await fetch(`${VITE_CATALOG_API}/datasets/${id}`, {
        method: "PATCH",
        body: JSON.stringify({ is_deleted: true }),
        headers: { "Content-Type": "application/json" },
      });

      if (!resp.ok) {
        throw Error("There was a problem trying to delete dataset");
      }

      mutate();
      navigate("/data-browser/datasets");
    } catch (e) {
      console.error(e);
      rumClient?.recordError(e);
      setError("Unable to delete dataset");
    }

    setSubmitting(false);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <TextOverflow>{`Delete "${name}"`}</TextOverflow>
        </DialogTitle>
        <DialogContent sx={{ gap: 2, fontSize: 14 }}>
          {error && <Alert severity="error">{error}</Alert>}
          Are you sure you want to delete this dataset?
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitting}
            sx={{
              backgroundColor: "attention.main",
              "&:hover": { backgroundColor: "attention.dark" },
            }}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteDatasetModal;
