import { FormEvent, useEffect, useRef, useState } from "react";
import { mutate } from "swr";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  OutlinedInput,
  Typography as T,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextOverflow from "~/components/TextOverflow";
import useCohorts from "cohorts/hooks/useCohorts";
import { trackEvent } from "~/utils/monitoring";

interface ModalProps {
  id: number;
  open: boolean;
  handleClose: () => void;
  name: string;
}

const ModalBody = ({ id, handleClose, name: originalName }: ModalProps) => {
  const { mutate: mutateCohorts } = useCohorts();
  const [error, setError] = useState("");
  const [name, setName] = useState(originalName);
  const [submitting, setSubmitting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    const body = {
      name: name,
    };
    const resp = await fetch(`/api/cohort-explorer/cohorts/${id}`, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.ok) {
      const data = await resp.json();
      mutate(`/api/cohort-explorer/cohorts/${id}`, data);
      await mutateCohorts();
      handleClose();
      trackEvent("cohort_updated", {
        cohort_id: id,
        content: body,
      });
    } else {
      setError("Unable to rename cohort");
    }

    setSubmitting(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>
        <TextOverflow>Rename Cohort</TextOverflow>
      </DialogTitle>
      <DialogContent sx={{ gap: 2, fontSize: 14 }}>
        {error && <Alert severity="error">{error}</Alert>}
        <FormControl>
          <FormLabel htmlFor="cohort-name">
            <T variant="label">New Name *</T>
          </FormLabel>
          <OutlinedInput
            autoComplete="off"
            autoFocus
            error={!!error}
            inputRef={inputRef}
            name="cohort-name"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={!name || name === originalName}
          loading={submitting}
          type="submit"
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const RenameCohortModal = (props: ModalProps) => (
  <Dialog fullWidth open={props.open} onClose={props.handleClose}>
    <ModalBody {...props} />
  </Dialog>
);

export default RenameCohortModal;
