import { useMemo } from "react";

const useFormattedText = (text: string) => {
  return useMemo(() => {
    const lines = text.split("\n");
    return lines.map((line, i, arr) => {
      const wrapped = <span key={i}>{line}</span>;
      if (i === arr.length - 1) {
        return wrapped;
      } else {
        return [wrapped, <br key={i + "br"} />];
      }
    });
  }, [text]);
};

export default useFormattedText;
