import { SyntheticEvent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  paperClasses,
  Portal,
  Stack,
  styled,
  Typography as T,
} from "@mui/material";
import ImportDetails from "datasets/components/ImportDetails";
import TableMetadataDetails from "datasets/components/TableMetadataDetails";
import { IngestForm } from "datasets/types/interfaces/ingest";
import ReviewDetails from "./ReviewDetails";

const StyledAccordian = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const StyledAccordianSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIos sx={{ transform: "rotate(90deg)", width: 16 }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.dimmedPageBackground,
  borderRadius: "6px",
  minHeight: 40,
}));

const StyledAccordianDetails = styled((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
))(() => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: 16,
}));

const CloudStorageForm = () => {
  const form = useForm<IngestForm>({
    mode: "onChange",
    // TODO: remove hard-coded form values during RWB-1588
    defaultValues: {
      source_file_url: "",
      metadata_config: {
        database: "",
        database_schema: "",
        table: "",
        table_description: "",
        tags: [],
        columns: [
          {
            name: "Column A",
            type: "VARCHAR",
            description: "Column description from metadata file",
            tags: [],
          },
          {
            name: "Column B",
            type: "CHAR",
            description:
              "Column description from metadata file. Column description from metadata file. Column description from metadata file. Column description from metadata file.",
            tags: [],
          },
          {
            name: "Column C",
            type: "FLOAT",
            description: "Column description from metadata file",
            tags: ["tag", "tag"],
          },
        ],
      },
    },
  });

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleExpand = (panel: string) => (_e: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  // TODO: add backend functionality in RWB-1588
  const onSubmit = async (data: IngestForm) => {
    console.log("handle the submit", data);
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack gap={1}>
            {/* 1. Data Import */}
            <StyledAccordian expanded={expanded === "panel1"} onChange={handleExpand("panel1")}>
              <StyledAccordianSummary>
                <T variant="body2" fontWeight={500}>
                  1. Data Import
                </T>
              </StyledAccordianSummary>
              <StyledAccordianDetails
                sx={{ maxWidth: 690, [`.${paperClasses.root}`]: { maxWidth: 658 } }}
              >
                <ImportDetails onNextPanel={() => setExpanded("panel2")} />
              </StyledAccordianDetails>
            </StyledAccordian>

            {/* 2. Table Configuration */}
            <StyledAccordian
              expanded={expanded === "panel2"}
              disabled={!form.watch("metadata_config.database")}
              onChange={handleExpand("panel2")}
            >
              <StyledAccordianSummary>
                <T variant="body2" fontWeight={500}>
                  2. Table Configuration
                </T>
              </StyledAccordianSummary>
              <StyledAccordianDetails>
                <TableMetadataDetails onNextPanel={() => setExpanded("panel3")} />
              </StyledAccordianDetails>
            </StyledAccordian>

            {/* 3. Review */}
            <StyledAccordian
              expanded={expanded === "panel3"}
              disabled={!form.watch("metadata_config.database")}
              onChange={handleExpand("panel3")}
            >
              <StyledAccordianSummary>
                <T variant="body2" fontWeight={500}>
                  3. Review
                </T>
              </StyledAccordianSummary>
              <StyledAccordianDetails>
                <ReviewDetails />
              </StyledAccordianDetails>
            </StyledAccordian>
          </Stack>
        </form>
      </FormProvider>
      {import.meta.env.DEV && (
        <Portal>
          <DevTool control={form.control} placement="bottom-right" />
        </Portal>
      )}
    </>
  );
};

export default CloudStorageForm;
