import { CardContent, Link } from "@mui/material";
import { ComponentProps, FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import InteractiveCard, { IconContainer } from "~/components/InteractiveCard";
import CT from "~/components/ClampedTypography";

interface TileProps {
  label: string;
  description: string;
  href: string;
  icon?: FunctionComponent<ComponentProps<"svg">>;
  iconColor?: string;
  backgroundColor?: string;
}

const DataBrowserTile = ({ href, icon: Icon, iconColor, label, description }: TileProps) => {
  return (
    <Link component={RouterLink} underline="none" to={href}>
      <InteractiveCard>
        <CardContent sx={{ gap: 1.25, height: "180px", px: 3, pt: 2.5 }}>
          {Icon && (
            <IconContainer>
              <Icon color={iconColor ?? "#31323C"} />
            </IconContainer>
          )}
          <CT variant="body1" fontWeight={500} lineHeight={1.2}>
            {label}
          </CT>
          <CT
            variant="body2"
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              opacity: 0.7,
              maxWidth: "75%",
            }}
          >
            {description}
          </CT>
        </CardContent>
      </InteractiveCard>
    </Link>
  );
};

export default DataBrowserTile;
