import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  inputClasses,
  OutlinedInput,
  Stack,
  Typography as T,
  useTheme,
} from "@mui/material";

// TODO: elaborate on form during RWB-1591
const SnowflakeForm = () => {
  const theme = useTheme();
  const form = useForm({
    defaultValues: {},
  });

  const onSubmit = async () => {
    console.log("handle the submit");
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack gap={3} alignItems="flex-start">
        <Stack gap={0.5} width="100%">
          <T variant="body2" fontWeight={500}>
            Table Location
          </T>
          <Box display="flex" alignItems="center" width="100%" gap={1.25}>
            <OutlinedInput
              fullWidth
              placeholder="Select table..."
              size="small"
              sx={{
                maxWidth: 440,
                [`.${inputClasses.inputSizeSmall}`]: {
                  height: "unset",
                  lineHeight: "20px",
                  p: 1,
                },
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              sx={{
                backgroundColor: theme.palette.grey[100],
              }}
            >
              Browse...
            </Button>
          </Box>
        </Stack>
        <LoadingButton type="submit" variant="contained">
          Add Table
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default SnowflakeForm;
