import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  chipClasses,
  inputClasses,
  Link,
  OutlinedInput,
  outlinedInputClasses,
  Stack,
  styled,
  Typography as T,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import PageHeader from "~/components/PageHeader";
import useDataset from "datasets/hooks/useDataset";
import useDatasetTable from "datasets/hooks/useDatasetTable";
import { PlusIcon } from "~/assets/icons";
import { Code } from "@mui/icons-material";
import useFormattedText from "~/pages/catalog/hooks/useFormattedText";

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  gap: 4,
  padding: 8,
  [`.${inputClasses.inputSizeSmall}`]: {
    height: "unset",
    lineHeight: "20px",
    padding: 0,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.grey[300],
  },
}));

const StyledTHeaderCell = styled(TableCell)({
  padding: "8px 6px",
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
});

const StyledTBodyCell = styled(TableCell)({
  padding: "16px 6px",
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
});

const DatasetTable = () => {
  const theme = useTheme();
  const { data: dataset } = useDataset();
  const { data: datasetTable } = useDatasetTable();

  const formattedDescription = useFormattedText(datasetTable?.description ?? "");

  return (
    <Stack gap={1.5}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Stack alignItems="flex-start">
          <Link
            component={RouterLink}
            color="text.primary"
            to={`/data-browser/datasets/${dataset?.id}/tables`}
            underline="hover"
            sx={{
              opacity: 0.6,
              textDecoration: "none",
            }}
          >
            All Tables
          </Link>
          <PageHeader>{datasetTable?.name}</PageHeader>
        </Stack>

        <Link
          target="_blank"
          href={`https://openmetadata.${window.location.hostname}/table/${datasetTable?.fullyQualifiedName}/lineage?fullscreen=true`}
        >
          <Button variant="outlined">View Lineage</Button>
        </Link>
      </Box>
      <T variant="caption" color="#757575">{`Location: ${[
        datasetTable?.database.name,
        datasetTable?.databaseSchema.name,
      ].join(".")}`}</T>
      {Boolean(formattedDescription) && <T fontSize={14}>{formattedDescription}</T>}
      {datasetTable?.tags && datasetTable.tags.length > 0 && (
        <Stack gap={1}>
          <T variant="body2" fontWeight={500}>
            Table Tags
          </T>
          <Box display="flex" alignItems="center" gap={0.5}>
            {datasetTable.tags.map((tag, i) => (
              <Chip key={i} label={tag.tagFQN.replace(".", " / ")} size="small" />
            ))}
          </Box>
        </Stack>
      )}
      <Stack>
        <T variant="body2" fontWeight={500}>
          Columns
        </T>
        <Stack gap={1.25}>
          <Box display="flex" alignItems="center" gap={1}>
            <StyledOutlinedInput
              fullWidth
              size="small"
              placeholder="Search by column name..."
              sx={{ maxWidth: 320 }}
            />
            <Button variant="outlined" startIcon={<Code />}>
              Code
            </Button>
          </Box>
          <TableContainer
            sx={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "grey.300",
              borderRadius: 1.5,
            }}
          >
            <Table size="small" sx={{ minWidth: 600, overflowX: "auto" }}>
              <TableHead
                sx={{
                  backgroundColor: theme.palette.common.dimmedPageBackground,
                }}
              >
                <TableRow>
                  <StyledTHeaderCell>Name</StyledTHeaderCell>
                  <StyledTHeaderCell>Type</StyledTHeaderCell>
                  <StyledTHeaderCell>Description</StyledTHeaderCell>
                  <StyledTHeaderCell>Tags</StyledTHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ p: 4 }}>
                {datasetTable?.columns.map((column, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      verticalAlign: "top",
                      "&:last-of-type td": { borderBottom: "unset" },
                    }}
                  >
                    <StyledTBodyCell>
                      <Box minHeight={36} display="flex" alignItems="center">
                        <T fontSize={14}>{column.name}</T>
                      </Box>
                    </StyledTBodyCell>
                    <StyledTBodyCell width="15%">
                      <Box minHeight={36} display="flex" alignItems="center">
                        <T fontSize={14}>{column.dataType}</T>
                      </Box>
                    </StyledTBodyCell>
                    <StyledTBodyCell width="45%">
                      <StyledOutlinedInput
                        value={column.description}
                        readOnly
                        autoComplete="off"
                        multiline
                        fullWidth
                        placeholder="Describe this column..."
                        sx={{
                          minHeight: 36,
                        }}
                      />
                    </StyledTBodyCell>
                    <StyledTBodyCell width="25%">
                      <Box display="flex" gap={0.75} alignItems="center" minHeight={36}>
                        {column.tags.length > 0 ? (
                          column.tags.map((tag, i) => (
                            <Chip
                              key={i}
                              label={tag.tagFQN.replace(".", " / ")}
                              size="small"
                              sx={{ fontSize: 13, [`& .${chipClasses.label}`]: { px: 1.25 } }}
                            />
                          ))
                        ) : (
                          <Button
                            variant="text"
                            size="small"
                            startIcon={<PlusIcon />}
                            sx={{ fontSize: 13, color: theme.palette.primary.main }}
                          >
                            Add
                          </Button>
                        )}
                      </Box>
                    </StyledTBodyCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DatasetTable;
