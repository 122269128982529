import { useNavigate, useParams } from "react-router-dom";
import { useFetcher } from "~/hooks/useFetcher";
import Dataset from "datasets/types/interfaces/dataset";

const { VITE_CATALOG_API } = import.meta.env;

const useDataset = (id?: string) => {
  const navigate = useNavigate();
  const { datasetId = id } = useParams();

  const resp = useFetcher<Dataset>(datasetId ? `${VITE_CATALOG_API}/datasets/${datasetId}` : null);

  if (resp.error?.status >= 400 && resp.error.status < 500) {
    navigate("/404", { replace: true });
  }

  return resp;
};

export default useDataset;
