import queryString from "query-string";
import { useNavigate, useParams } from "react-router-dom";
import { useFetcher } from "~/hooks/useFetcher";
import { TableEntity } from "~/types/interfaces/catalogResponse";

const { VITE_CATALOG_API } = import.meta.env;

const useDatasetTable = () => {
  const navigate = useNavigate();
  const { tableFQN } = useParams();
  const tableQueryString = queryString.stringify({
    fields: "description,columns,tags",
  });

  const resp = useFetcher<TableEntity>(
    tableFQN ? `${VITE_CATALOG_API}/om/v1/tables/name/${tableFQN}?${tableQueryString}` : null
  );

  if (resp.error?.status >= 400 && resp.error.status < 500) {
    navigate("/404", { replace: true });
  }

  return resp;
};

export default useDatasetTable;
