import { cloneElement, MouseEvent, ReactElement, useState } from "react";
import {
  IconButton,
  Popper,
  Paper,
  MenuList,
  ClickAwayListener,
  useTheme,
  PopperPlacementType,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

const ActionsMenu = ({
  disablePortal = false,
  menuItems = [],
  placement = "bottom",
  visible,
}: {
  disablePortal?: boolean;
  menuItems: ReactElement[];
  placement?: PopperPlacementType;
  visible: boolean;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleItemClicked = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleClose(event);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        sx={{ opacity: visible || open ? 1 : 0, transition: "opacity 150ms ease-in-out" }}
      >
        <MoreHoriz fontSize="small" />
      </IconButton>
      {/**
       * We're intentionally not using <Menu/> here. The project card that uses this component
       * has a scale transform on hover. That transform throws off the positioning
       * of the Menu component and was simply taking too long to debug. Using a Popper directly
       * does not have this issue.
       */}
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        disablePortal={disablePortal}
        placement={placement}
        sx={{ zIndex: theme.zIndex.modal }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList dense>
              {menuItems.map((item) =>
                cloneElement(item, {
                  onClick: (e: MouseEvent) => {
                    handleItemClicked(e);
                    if (item.props.onClick) {
                      item.props.onClick(e);
                    }
                  },
                })
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default ActionsMenu;
