import { FormEvent, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { trackEvent } from "~/utils/monitoring";
import TextOverflow from "~/components/TextOverflow";
import useCohorts from "cohorts/hooks/useCohorts";

interface ModalProps {
  id: number;
  open: boolean;
  handleClose: () => void;
  name: string;
}

const ModalBody = ({ id, handleClose, name }: ModalProps) => {
  const { mutate: mutateCohorts } = useCohorts();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError("");
    const resp = await fetch(`/api/cohort-explorer/cohorts/${id}`, {
      method: "DELETE",
    });

    if (resp.ok) {
      await mutateCohorts();

      trackEvent("cohort_deleted", {
        cohort_id: id,
      });

      handleClose();
    } else {
      setError("Unable to delete cohort");
    }

    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>
        <TextOverflow>Delete &quot;{name}&quot;</TextOverflow>
      </DialogTitle>
      <DialogContent sx={{ gap: 2, fontSize: 14 }}>
        {error && <Alert severity="error">{error}</Alert>}
        <Box>Are you sure you want to delete this cohort?</Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={submitting}
          sx={{
            backgroundColor: "attention.main",
            "&:hover": { backgroundColor: "attention.dark" },
          }}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

const DeleteCohortModal = (props: ModalProps) => (
  <Dialog fullWidth open={props.open} onClose={props.handleClose}>
    <ModalBody {...props} />
  </Dialog>
);

export default DeleteCohortModal;
