import AccentColor from "datasets/types/enums/accent-color";

/**
 * Returns the datasets accent color if it exists from the provided palette, or defaults to Purple
 */
export const getAccentColor = (color?: string): AccentColor => {
  return color && Object.values(AccentColor).includes(color as AccentColor)
    ? (color as AccentColor)
    : AccentColor.PURPLE;
};
