import yn from "yn";
import { Alert, Skeleton } from "@mui/material";
import { ComponentProps, FunctionComponent, useMemo } from "react";
import {
  ColumnsIcon,
  DatasetIcon,
  FilesToolIcon,
  PinOutlinedIcon,
  ReportsIcon,
  TablesIcon,
} from "~/assets/icons";
import useUser from "~/contexts/user";
import DataBrowserTile from "~/components/DataBrowserTile";
import CardGrid from "~/components/CardGrid";
import { TableEntity, VariableEntity } from "~/types/interfaces/catalogResponse";
import useCatalogEnabled from "catalog/hooks/useCatalogEnabled";
import useCatalogSearch from "catalog/hooks/useCatalogSearch";
import usePinnedEntities from "catalog/hooks/usePinnedEntities";

const { VITE_DATA_DASHBOARD_URL, VITE_DATASETS_ENABLED, VITE_SIGMA_REPORT_IDS } = import.meta.env;

interface TileProps {
  label: string;
  description: string;
  href: string;
  icon?: FunctionComponent<ComponentProps<"svg">>;
  iconColor?: string;
  backgroundColor?: string;
}

const numFormatter = Intl.NumberFormat("en", { notation: "compact" });

const useDataBrowserSections = () => {
  const user = useUser();
  const catalogEnabled = useCatalogEnabled();
  const {
    data: tables,
    isLoading: loadingTables,
    error: tableError,
  } = useCatalogSearch<TableEntity>("table");
  const {
    data: columns,
    isLoading: loadingColumns,
    error: columnError,
  } = useCatalogSearch<VariableEntity>("column");
  const { total: totalPinned } = usePinnedEntities();

  const loading = !user || loadingTables || loadingColumns;
  const tablesTotal = Number(tables?.hits?.total?.value);
  const columnsTotal = Number(columns?.hits?.total?.value);

  const sections = useMemo(() => {
    const assetTiles: TileProps[] = [];

    if (VITE_DATA_DASHBOARD_URL || VITE_SIGMA_REPORT_IDS) {
      assetTiles.push({
        href: "/data-browser/reports",
        label: "Reports",
        description: "View curated reports to explore your organization's data.",
        icon: ReportsIcon,
      });
    }

    if (yn(VITE_DATASETS_ENABLED)) {
      assetTiles.push({
        href: "/data-browser/datasets",
        label: "Datasets",
        description: "Browse collections of data organized by purpose",
        icon: DatasetIcon,
      });
    }

    if (catalogEnabled) {
      if (tablesTotal) {
        assetTiles.push({
          href: "/catalog/tables",
          label: "Tables",
          description: `${numFormatter.format(tablesTotal)} tables`,
          icon: TablesIcon,
        });
      }

      if (columnsTotal) {
        assetTiles.push({
          href: "/catalog/columns",
          label: "Columns",
          description: `${numFormatter.format(columnsTotal)} columns`,
          icon: ColumnsIcon,
        });
      }

      if (totalPinned) {
        assetTiles.push({
          href: "/catalog/pins",
          label: "Pins",
          description: `${numFormatter.format(totalPinned)} pinned`,
          icon: PinOutlinedIcon,
        });
      }
    }

    assetTiles.push({
      href: "/files",
      label: "Files",
      description: "Personal, workbench and organization files",
      icon: FilesToolIcon,
    });

    return assetTiles;
  }, [catalogEnabled, tablesTotal, columnsTotal, totalPinned]);

  return { sections, loading, error: Boolean(tableError || columnError) };
};

const Loading = () => (
  <>
    {Array.from({ length: 4 }).map((_, i) => (
      <Skeleton key={i} variant="rounded" height={180} />
    ))}
  </>
);

export const DataBrowserSections = () => {
  const { sections, loading, error } = useDataBrowserSections();

  if (error) {
    return (
      <Alert severity="error" variant="outlined">
        Unable to load data
      </Alert>
    );
  }

  return (
    <CardGrid>
      {loading ? (
        <Loading />
      ) : (
        sections.map((props) => <DataBrowserTile key={props.label} {...props} />)
      )}
    </CardGrid>
  );
};

export default DataBrowserSections;
