import { ComponentProps, FunctionComponent } from "react";
import {
  BiospecimenIcon,
  DataOverview,
  GenomicsIcon,
  TissueSampleIcon,
  LocationIcon,
  CancerIcon,
} from "~/assets/icons";

enum Report {
  OVERVIEW = "overview",
  BIOSPECIMEN = "biospecimen",
  TISSUE = "tissue",
  BLOOD = "blood",
  GENOMICS = "genomics",
  GEOSPATIAL = "geospatial",
  BREAST_CANCER = "breast-cancer",
}
interface ReportDisplay {
  ids: string[];
  label: string;
  description: string;
  icon: FunctionComponent<ComponentProps<"svg">>;
  iconColor: string;
}

export const DISPLAY_REPORT: Record<Report, ReportDisplay> = {
  [Report.OVERVIEW]: {
    ids: [
      "Overview-Report-2z3oTQpFRxQ9Uv6XbEpwJJ",
      "Overview-Report-12tSRUxf6QMjDW7Ldak26U/page/snJelUA8R4",
      "Overview-Report-Manifold-stage-4NDqcCyDWRr0aEZaSm0d37/page/snJelUA8R4",
      "Overview-Report-Manifold-3Yf7v1WHhbCPjtIY4TFrDn/page/snJelUA8R4",
      "Overview-Report-UVA-stage-2giqaD8fLTyohWLxw4c3zI/page/snJelUA8R4",
      "Overview-Report-UVA-5Xmp8Ryx5fHI9aBLARe7YA/page/snJelUA8R4",
    ],
    label: "Overview",
    description: "Start here to get an overview of data in the platform",
    icon: DataOverview,
    iconColor: "#2225F5",
  },
  [Report.BIOSPECIMEN]: {
    ids: [
      "Biospecimen-Report-Manifold-Stage-eEzqADdqn6d5XZDQG340B/page/H6lB7taSnr",
      "Biospecimen-Report-Manifold-12BYMpGavntYA2Zx0f8zE8/page/H6lB7taSnr",
      "Biospecimen-Report-UVA-stage-58ESxbc79KVH7UffjmM5Xj/page/H6lB7taSnr",
      "Biospecimen-Report-UVA-7gQqwxmGHXBngaasnyBfrp/page/H6lB7taSnr",
      "Biospecimen-Report-7ivfa44hsPvWNK50ZAM0oz",
      "Biospecimen-Report-5CSvFvHkLPi8vZjmMbUAD8",
    ],
    label: "Biospecimen Availability",
    description: "Overview of available specimens",
    icon: BiospecimenIcon,
    iconColor: "#E14B4B",
  },
  [Report.TISSUE]: {
    ids: [],
    label: "Tissue and Bone Marrow Availability",
    description: "Overview of solid specimens",
    icon: TissueSampleIcon,
    iconColor: "#FF4C1A",
  },
  [Report.BLOOD]: {
    ids: [],
    label: "Blood Availability",
    description: "Overview of blood specimens",
    icon: BiospecimenIcon,
    iconColor: "#E14B4B",
  },
  [Report.GENOMICS]: {
    ids: [
      "Genomics-Report-1Q2Lz0rQH0OuL09MpGuWwG/page/ApZslS2VUx",
      "Genomics-Report-DKS1yk3P4skfICdC0VcZe/page/Ofwyqr_K-H",
      "Genomics-Report-Manifold-Stage-7x5o2p3fBloHaJ1fXvThmi/page/20-V34RcgR",
      "Genomics-Report-Manifold-XOzuItVv1SPm0xF8DVZns/page/Ofwyqr_K-H",
      "Genomics-Report-UVA-stage-5URaIlj8KBDmU7Kyyk3Zgw/page/Ofwyqr_K-H",
      "Genomics-Report-UVA-77MtkkBLSSZuhAGElISPZn/page/Ofwyqr_K-H",
    ],
    label: "Genomics",
    description: "Overview of genomics sequencing files and indexed variants",
    icon: GenomicsIcon,
    iconColor: "#651EFE",
  },
  [Report.GEOSPATIAL]: {
    ids: ["Map-Report-59yk5ovDipVnoQx650UdnC/page/e2j1yP1TfZ"],
    label: "Geospatial",
    description: "Visualize population on a map",
    icon: LocationIcon,
    iconColor: "#008CF2",
  },
  [Report.BREAST_CANCER]: {
    ids: ["Breast-Cancer-Manifold-Prod-2hVbvwjp3ShIBjzD4qbBh0/page/AnBwjyBTIQ"],
    label: "Breast Cancer",
    description: "Deep dive into breast cancer cases",
    icon: CancerIcon,
    iconColor: "#DD37F8",
  },
};

export default Report;
