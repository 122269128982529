import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  RadioGroup,
  Stack,
  Typography as T,
  useTheme,
} from "@mui/material";
import useErrorToast from "~/contexts/error-toast";
import rumClient from "~/utils/monitoring";
import useDataset from "datasets/hooks/useDataset";
import useDatasets from "datasets/hooks/useDatasets";
import ColorRadio from "datasets/components/ColorRadio";
import DeleteDatasetModal from "datasets/components/DeleteDatasetModal";
import AccentColor from "datasets/types/enums/accent-color";
import Dataset from "datasets/types/interfaces/dataset";
import { getAccentColor } from "datasets/utils/color";

const { VITE_CATALOG_API } = import.meta.env;

const formatDateTime = (timestamp: string) => {
  const date = new Date(timestamp);
  return format(date, "MM/dd/yyyy 'at' h:mma");
};

type DatasetSettingsForm = { accentColor: string };

const DatasetSettings = () => {
  const theme = useTheme();
  const { data: dataset, mutate } = useDataset();
  const { mutate: mutateDatasets } = useDatasets();
  const { showError } = useErrorToast();
  const form = useForm<DatasetSettingsForm>({
    defaultValues: { accentColor: getAccentColor(dataset?.accentColor) },
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const selectedColor = form.watch("accentColor");

  useEffect(() => {
    if (dataset) {
      form.reset({ accentColor: dataset.accentColor });
    }
  }, [dataset, form]);

  useEffect(() => {
    if (!selectedColor || !dataset || selectedColor === dataset.accentColor) return;

    const controller = new AbortController();

    const updateDatasetColor = async () => {
      try {
        const resp = await fetch(`${VITE_CATALOG_API}/datasets/${dataset.id}`, {
          method: "PATCH",
          body: JSON.stringify({
            accentColor: selectedColor,
          }),
          signal: controller.signal,
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!resp) {
          throw Error("There was a problem trying to update the dataset");
        }

        const updatedDataset: Dataset = await resp.json();
        mutate(updatedDataset, false);
        mutateDatasets();
      } catch (e) {
        console.error(e);
        rumClient?.recordError(e);
        showError("Unable to update dataset");
      }
    };

    updateDatasetColor();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColor]);

  return (
    <TabPanel value="settings">
      <Container>
        <Stack>
          <Controller
            name="accentColor"
            control={form.control}
            render={({ field, formState }) => (
              <Stack gap={2} borderBottom={`1px solid ${theme.palette.grey[300]}`} pb={2}>
                <T fontSize={14} fontWeight={600}>
                  Accent Color
                </T>
                <RadioGroup
                  {...field}
                  row
                  value={field.value}
                  onChange={(_, val) => field.onChange(val)}
                >
                  {Object.values(AccentColor).map((option) => (
                    <ColorRadio
                      key={option}
                      disabled={formState.isSubmitting || formState.isLoading}
                      value={option}
                      onChange={field.onChange}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            )}
          />

          <Stack gap={1} borderBottom={`1px solid ${theme.palette.grey[300]}`} py={2}>
            <T fontSize={14} fontWeight={600}>
              Created
            </T>
            <T variant="body2">{dataset?.created_at && formatDateTime(dataset.created_at)}</T>
          </Stack>

          <Stack gap={1} borderBottom={`1px solid ${theme.palette.grey[300]}`} py={2}>
            <T fontSize={14} fontWeight={600}>
              Updated at
            </T>
            <T variant="body2">{dataset?.updated_at && formatDateTime(dataset.updated_at)}</T>
          </Stack>

          <Box pt={3}>
            <Button variant="contained" color="error" onClick={() => setDeleteModalOpen(true)}>
              Delete Dataset
            </Button>
          </Box>
        </Stack>
      </Container>
      {dataset && (
        <DeleteDatasetModal
          id={dataset.id}
          name={dataset.title}
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
    </TabPanel>
  );
};

export default DatasetSettings;
