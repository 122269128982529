import { KeyboardEvent, MouseEvent, PropsWithChildren, ReactElement, ReactNode } from "react";
import { useToggle } from "react-use";
import { To, useNavigate, useResolvedPath } from "react-router-dom";
import { NavigateOptions } from "react-router/dist/lib/context";
import { Box, Collapse, List, Skeleton, useTheme, SxProps } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import FlatCard from "~/components/FlatCard";

const StyledListItemSummary = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  ".rowContent": {
    display: "flex",
    overflow: "hidden",
    padding: `${theme.spacing(1.25)} 0`,
    gap: theme.spacing(2),
    width: "100%",
    transition: "border-color .2s cubic-bezier(.32,.1,.25,1)",
  },
}));

const StyledCTASummary = styled(StyledListItemSummary)(({ theme }) => ({
  userSelect: "none",
  "&:not(.disabled)": {
    cursor: "pointer",
    "&:active": {
      background: theme.palette.grey[300],
    },
  },
  "&.disabled": { opacity: 0.8 },
  "&:hover": {
    background: theme.palette.grey[200],
    ".rowContent": {
      borderColor: "transparent",
    },
    button: { opacity: 1 },
  },
}));

const StyledListItem = styled("li")(({ theme }) => ({
  "&:not(:first-of-type) .rowContent": {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  "&.hoverable:not([aria-expanded='true']):hover + * .rowContent": {
    borderColor: "transparent",
  },
}));

const LoadingState = ({
  count = 6,
  height = 60,
  radius,
}: {
  count?: number;
  height?: number | string;
  radius?: number | string;
}) => {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <Skeleton
          key={i}
          component="li"
          height={height}
          variant="rounded"
          sx={{ borderRadius: radius, "&:not(:last-of-type)": { mb: 2 } }}
        />
      ))}
    </>
  );
};

interface ListItemContentProps extends PropsWithChildren {
  icon?: ReactElement;
}

const ListItemContent = ({ children, icon }: ListItemContentProps) => (
  <Box display="flex" alignItems="center" px={1.25} flex={1}>
    {icon && (
      <Box
        className="iconContainer"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 32,
          height: 32,
          mr: 1.25,
        }}
      >
        {icon}
      </Box>
    )}
    <Box className="rowContent">{children}</Box>
  </Box>
);

interface ListItemProps extends ListItemContentProps {
  rootStyles?: SxProps;
}

export const ListItem = ({ icon, rootStyles, children }: ListItemProps) => {
  return (
    <StyledListItem>
      <StyledListItemSummary sx={rootStyles}>
        <ListItemContent icon={icon}>{children}</ListItemContent>
      </StyledListItemSummary>
    </StyledListItem>
  );
};

interface ExpandableListItemProps extends ListItemProps {
  expansionContent: ReactNode;
  disabled?: boolean;
  indicator?: boolean;
}

export const ExpandableListItem = ({
  expansionContent,
  icon,
  disabled,
  rootStyles,
  children,
  indicator = false,
}: ExpandableListItemProps) => {
  const { transitions } = useTheme();
  const [expanded, toggleExpanded] = useToggle(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (disabled) {
      return event.preventDefault();
    }

    if (event.key === "Enter") {
      toggleExpanded();
    }
  };

  return (
    <StyledListItem aria-expanded={expanded} className="hoverable">
      <StyledCTASummary
        sx={rootStyles}
        role="button"
        tabIndex={disabled ? -1 : 0}
        className={disabled ? "disabled" : ""}
        onClick={disabled ? () => {} : toggleExpanded}
        onKeyDown={handleKeyDown}
      >
        <ListItemContent icon={icon}>
          {children}
          {indicator && (
            <Box sx={{ display: "flex", ml: "auto", opacity: 0.7 }}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </Box>
          )}
        </ListItemContent>
      </StyledCTASummary>
      <Collapse in={expanded} timeout={transitions.duration.shorter} unmountOnExit>
        <Box px={2} py={1}>
          {expansionContent}
        </Box>
      </Collapse>
    </StyledListItem>
  );
};

interface LinkListItemProps extends ListItemProps {
  to: To;
  disabled?: boolean;
  navOptions?: NavigateOptions;
}

export const LinkListItem = ({
  to,
  disabled,
  navOptions,
  icon,
  rootStyles,
  children,
}: LinkListItemProps) => {
  const navigate = useNavigate();
  const { pathname, search } = useResolvedPath(to, navOptions);

  const handleClick = (event?: MouseEvent) => {
    if (disabled) {
      return event?.preventDefault();
    }

    // Check if Command (metaKey) or Alt (altKey) is pressed on macOS/Windows/Linux
    if (event?.metaKey || event?.altKey) {
      // Open in a new tab using resolved url
      window.open(pathname + search, "_blank");
    } else {
      // Navigate in the same tab
      navigate(to, navOptions);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (disabled) {
      return event.preventDefault();
    }

    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <StyledListItem className="hoverable">
      <StyledCTASummary
        sx={{
          ...rootStyles,
        }}
        role="link"
        className={disabled ? "disabled" : ""}
        tabIndex={disabled ? -1 : 0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <ListItemContent icon={icon}>{children}</ListItemContent>
      </StyledCTASummary>
    </StyledListItem>
  );
};

interface ListCardProps {
  borderTop?: boolean;
  children?: ReactNode;
  footer?: ReactElement | null;
  header?: ReactElement | null;
  listStyles?: SxProps;
  loading?: boolean;
  placeholder?: {
    count?: number;
    height?: number | string;
    radius?: number | string;
  };
  sx?: SxProps;
}

const ListCard = ({
  borderTop = false,
  children = null,
  footer = null,
  header = null,
  listStyles,
  loading = false,
  placeholder = {},
  sx,
}: ListCardProps) => {
  const theme = useTheme();

  return (
    <FlatCard sx={sx}>
      {header}
      <List
        sx={{
          p: 1.25,
          "& li:first-of-type > div:first-of-type": {
            ".rowContent": {
              borderTop: borderTop ? `1px solid ${theme.palette.grey[200]}` : "",
            },
          },
          "& .hoverable:first-of-type > div:first-of-type:hover": {
            ".rowContent": { borderColor: "transparent" },
          },
          ...listStyles,
        }}
      >
        {loading ? <LoadingState {...placeholder} /> : children}
      </List>
      {footer}
    </FlatCard>
  );
};

export default ListCard;
