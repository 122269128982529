import { MouseEvent, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExpandMore } from "@mui/icons-material";
import { LoadingButton, loadingButtonClasses } from "@mui/lab";
import {
  Box,
  Button,
  buttonClasses,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  inputClasses,
  List,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  outlinedInputClasses,
  Popover,
  Stack,
  styled,
  Typography as T,
  useTheme,
} from "@mui/material";
import { SearchIcon } from "~/assets/icons";
import useSnowflakeSchemas from "datasets/hooks/useSnowflakeSchemas";
import { IngestForm } from "datasets/types/interfaces/ingest";

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  gap: 4,
  padding: 8,
  [`.${inputClasses.inputSizeSmall}`]: {
    height: "unset",
    lineHeight: "20px",
    padding: 0,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.grey[300],
  },
}));

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  [`&.${buttonClasses.outlinedPrimary}`]: {
    borderColor: theme.palette.grey[300],
    justifyContent: "space-between",
    lineHeight: "20px",
    padding: 8,
    "&:hover": {
      backgroundColor: "#FFFFFF",
      borderColor: theme.palette.primary.dark,
    },
  },
  [`& .${buttonClasses.endIcon}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${loadingButtonClasses.loadingIndicator}`]: {
    color: theme.palette.primary.dark,
  },
}));

const SchemaSelect = ({ onChange, value }: { onChange: (arg: string) => void; value: string }) => {
  const theme = useTheme();
  const { data: schemas, isLoading } = useSnowflakeSchemas();

  const [schemaAnchorEl, setSchemaAnchorEl] = useState<null | HTMLElement>(null);
  const [schemaSearchTerm, setSchemaSearchTerm] = useState("");
  const [selectedSchema, setSelectedSchema] = useState("");

  const filteredSchemas = schemas?.filter((schema) =>
    schema.name.toLowerCase().includes(schemaSearchTerm.toLowerCase())
  );

  const handleSchemasOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setSchemaAnchorEl(event.currentTarget);
  };

  const handleSchemasClose = () => {
    setSchemaAnchorEl(null);
  };

  const handleSelectSchema = () => {
    onChange(selectedSchema);
    setSchemaAnchorEl(null);
  };

  return (
    <>
      <T variant="body2" fontWeight={500}>
        Destination Snowflake Schema
      </T>
      <StyledLoadingButton
        size="small"
        endIcon={<ExpandMore />}
        loading={isLoading}
        loadingPosition="end"
        onClick={handleSchemasOpen}
        sx={{ justifyContent: "space-between" }}
        variant="outlined"
      >
        <T fontSize="inherit" sx={{ opacity: value ? 1 : 0.5 }}>
          {value ? value : "Select Schema..."}
        </T>
      </StyledLoadingButton>
      <Popover
        disablePortal
        className="schemaMenu"
        anchorEl={schemaAnchorEl}
        open={!!schemaAnchorEl}
        onClose={handleSchemasClose}
        PaperProps={{
          sx: {
            boxShadow:
              "0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033",
            width: "100%",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack alignItems="flex-end" p={2} gap={1.25}>
          <StyledOutlinedInput
            fullWidth
            placeholder="Search schemas..."
            size="small"
            startAdornment={<SearchIcon width={16} />}
            onChange={(e) => setSchemaSearchTerm(e.target.value)}
          />
          <Stack
            sx={{
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: 1.5,
              width: "100%",
            }}
          >
            <List dense disablePadding sx={{ height: 290, overflow: "auto" }}>
              {filteredSchemas?.map((schema, i) => (
                <ListItemButton
                  key={i}
                  selected={selectedSchema === schema.name}
                  onClick={() => setSelectedSchema(schema.name)}
                >
                  <ListItemText>{schema.name}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Stack>
          <Box display="flex" gap={1}>
            <Button variant="outlined" onClick={handleSchemasClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSelectSchema}>
              Select
            </Button>
          </Box>
        </Stack>
      </Popover>
    </>
  );
};

const ImportDetails = ({ onNextPanel }: { onNextPanel: () => void }) => {
  const theme = useTheme();
  const { control, watch } = useFormContext<IngestForm>();

  const [sourceModalOpen, setSourceModalOpen] = useState(false);

  const handleSourceModalOpen = () => {
    setSourceModalOpen(true);
  };

  const handleSourceModalClose = () => {
    setSourceModalOpen(false);
  };

  const handleSelectSourceFile = () => {
    // TODO: set the source file uri in a form value
    setSourceModalOpen(false);
  };

  return (
    <>
      <Stack gap={0.5} width="100%">
        <T variant="body2" fontWeight={500}>
          Source File
        </T>
        <Box display="flex" alignItems="center" width="100%" gap={1.25}>
          <StyledOutlinedInput
            name="source_data_file"
            fullWidth
            readOnly
            placeholder="Select data file..."
            size="small"
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSourceModalOpen}
            sx={{
              backgroundColor: theme.palette.grey[100],
            }}
          >
            Browse...
          </Button>
        </Box>
      </Stack>

      <Stack gap={0.5} width="100%">
        <Controller
          control={control}
          name="metadata_config.database_schema"
          render={({ field }) => <SchemaSelect {...field} />}
        />
      </Stack>

      <Stack gap={0.5} width="100%">
        <Controller
          control={control}
          name="metadata_config.database"
          render={({ field }) => (
            <>
              <T variant="body2" fontWeight={500}>
                Table Name
              </T>
              <StyledOutlinedInput
                {...field}
                fullWidth
                placeholder="Enter a name for your new table..."
                size="small"
              />
            </>
          )}
        />
      </Stack>

      <Button
        variant="contained"
        disabled={!watch("metadata_config.database")}
        onClick={() => onNextPanel()}
      >
        Next
      </Button>

      <Dialog fullWidth open={sourceModalOpen} onClose={handleSourceModalClose}>
        <DialogTitle>
          <T>Select Source File</T>
        </DialogTitle>
        {/* TODO: add file tree component in RWB-1588 */}
        <DialogContent>File tree</DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleSourceModalClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSelectSourceFile}>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportDetails;
