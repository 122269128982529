import { useFetcher } from "~/hooks/useFetcher";
import Dataset from "datasets/types/interfaces/dataset";

const { VITE_CATALOG_API } = import.meta.env;

const useDatasets = () => {
  return useFetcher<Dataset[]>(`${VITE_CATALOG_API}/datasets`);
};

export default useDatasets;
