import yn from "yn";
import { SyntheticEvent } from "react";
import { Navigate, Outlet, RouteObject, useNavigate, useParams } from "react-router-dom";
import { TabContext, TabList } from "@mui/lab";
import { Stack, styled, Tab, tabClasses, tabsClasses, useTheme } from "@mui/material";
import useAppHeader from "~/contexts/header";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import { useSafeRoutes } from "~/hooks/useSafeRoutes";
import { TitleBreadcrumbs } from "~/components/AppHeader";
import useDataset from "datasets/hooks/useDataset";
import DatasetOverview from "datasets/components/DatasetOverview";
import DatasetSettings from "datasets/components/DatasetSettings";
import DatasetTables from "datasets/components/DatasetTables";

const { VITE_DATASETS_ENABLED } = import.meta.env;

const routeDefinitions: RouteObject[] = [
  { index: true, element: <Navigate to="overview" replace /> },
  { path: "overview", element: <DatasetOverview /> },
  { path: "tables/*", element: <DatasetTables /> },
  { path: "settings", element: <DatasetSettings /> },
];

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 500,
  opacity: 0.75,
  padding: "20px 0",
  transition: "all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)",
  [`&.${tabClasses.selected}`]: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    opacity: 1,
  },
}));

const DatasetDetails = () => {
  const theme = useTheme();
  const { datasetId, "*": subRoute } = useParams<{ datasetId: string; "*": string }>();
  const navigate = useNavigate();
  const routes = useSafeRoutes(routeDefinitions);
  const { data: dataset } = useDataset(datasetId);
  useDocumentTitle(dataset?.title);
  useAppHeader({
    title: (
      <TitleBreadcrumbs
        crumbs={[
          { title: "Data Browser", href: "/data-browser" },
          { title: "Datasets", href: "/data-browser/datasets" },
          { title: dataset?.title, href: location.pathname },
        ]}
      />
    ),
  });

  const currentTab = subRoute?.split("/")[0] || "overview";

  const handleTabChange = (e: SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  if (!yn(VITE_DATASETS_ENABLED)) {
    return <Navigate to="/404" replace />;
  }

  return (
    <Stack bgcolor="common.pageBackground" display="flex" minHeight="100%">
      <TabContext value={currentTab}>
        <TabList
          onChange={handleTabChange}
          sx={{
            backgroundColor: theme.palette.common.white,
            position: "sticky",
            px: 3,
            top: 0,
            zIndex: theme.zIndex.appBar - 1,
            [`& .${tabsClasses.flexContainer}`]: {
              gap: 3,
            },
            [`& .${tabsClasses.indicator}`]: {
              backgroundColor: "text.primary",
            },
          }}
        >
          <StyledTab label="Overview" value="overview" />
          <StyledTab label="Tables" value="tables" />
          <StyledTab label="Settings" value="settings" />
        </TabList>
        {routes}
        <Outlet />
      </TabContext>
    </Stack>
  );
};

export default DatasetDetails;
