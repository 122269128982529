import { useState } from "react";
import AppContainer from "~/components/AppContainer";
import { useFetcher } from "~/hooks/useFetcher";
import monitoring from "~/utils/monitoring";

const { VITE_ACCESS_CONTROLLER_API, VITE_SIGMA_URL } = import.meta.env;

const getAccessControllerUrl = (workbookUrl: string): string | null => {
  try {
    const url = new URL(workbookUrl);
    const workbookRegex = /^\/manifold\/workbook\/.*$/;
    if (workbookRegex.test(url.pathname)) {
      let sourceUrl = `${url.origin}${url.pathname}`;
      const selectedNode = url.searchParams.get(":nodeId");
      if (selectedNode && !sourceUrl.includes("/page/")) {
        // Page element is selected in url using query string instead of context path, reformat for request
        sourceUrl += `/page/${selectedNode}`;
      }
      // V2 embed url pattern
      return `${VITE_ACCESS_CONTROLLER_API}/users/sigma/embed-url?source_url=${sourceUrl}`;
    }
  } catch (e) {
    console.error(e);
    monitoring?.recordError(e);
  }
  return null;
};

const SigmaEmbed = (props: { workbookUrl: string }) => {
  const [loading, setLoading] = useState(true);
  const refreshInterval = 24 * 60 * 60 * 1000;
  const leeway = 5 * 1000;
  const { data: sigmaEmbedUrl } = useFetcher<{ url: string }>(
    getAccessControllerUrl(props.workbookUrl),
    {
      // Refresh every 24 hours (with 5 seconds of leeway)
      refreshInterval: refreshInterval - leeway,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
    }
  );

  return (
    <AppContainer
      key={props.workbookUrl}
      loading={loading}
      iframeSrc={sigmaEmbedUrl?.url}
      messageHandler={(e) => {
        if (
          e.origin === VITE_SIGMA_URL &&
          ["workbook:loaded", "workbook:error"].includes(e.data.type)
        ) {
          setLoading(false);
        }
      }}
    />
  );
};

export default SigmaEmbed;
