import yn from "yn";
import useUser from "~/contexts/user";

const { VITE_DATASETS_ENABLED } = import.meta.env;

const useDatasetsEnabled = (): boolean => {
  const user = useUser();
  return (
    yn(VITE_DATASETS_ENABLED, { default: false }) &&
    !!user?.permissions?.includes("list:catalog-dataset")
  );
};

export default useDatasetsEnabled;
