import { styled, Typography as T } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TypographyTypeMap } from "@mui/material/Typography/Typography";

const ClampedTypography = styled(T)(() => ({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
})) as OverridableComponent<TypographyTypeMap>;

export default ClampedTypography;
