import pluralize from "pluralize";
import yn from "yn";
import { ComponentProps, FunctionComponent } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { Alert, Box, Container, ListItemIcon, ListItemText } from "@mui/material";
import {
  ColumnsIcon,
  DatasetIcon,
  FilesToolIcon,
  PinOutlinedIcon,
  ReportsIcon,
  TablesIcon,
} from "~/assets/icons";
import useAppHeader from "~/contexts/header";
import useUser from "~/contexts/user";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import ListCard, { LinkListItem } from "~/components/ListCard";
import { TableEntity, VariableEntity } from "~/types/interfaces/catalogResponse";
import useCatalogEnabled from "catalog/hooks/useCatalogEnabled";
import useCatalogSearch from "catalog/hooks/useCatalogSearch";
import usePinnedEntities from "catalog/hooks/usePinnedEntities";
import useDatasetsEnabled from "datasets/hooks/useDatasetsEnabled";

const { VITE_DATA_DASHBOARD_URL, VITE_DATASETS_ENABLED, VITE_SIGMA_REPORT_IDS } = import.meta.env;

const numFormatter = Intl.NumberFormat("en", { notation: "compact" });

const title = "Data Browser";

const DataBrowserList = () => {
  useAppHeader({ title });
  useDocumentTitle(title);
  const user = useUser();
  const datasetsEnabled = useDatasetsEnabled();
  const catalogEnabled = useCatalogEnabled();
  const {
    data: tables,
    isLoading: loadingTables,
    error: tableError,
  } = useCatalogSearch<TableEntity>("table");
  const {
    data: columns,
    isLoading: loadingColumns,
    error: columnError,
  } = useCatalogSearch<VariableEntity>("column");
  const { total: totalPinned } = usePinnedEntities();

  const loading = !user || loadingTables || loadingColumns;
  const error = Boolean(tableError || columnError);
  const tablesTotal = Number(tables?.hits?.total?.value);
  const columnsTotal = Number(columns?.hits?.total?.value);

  const listItems: {
    href: string;
    label: string;
    description: string;
    icon: FunctionComponent<ComponentProps<"svg">>;
  }[] = [];

  if (VITE_DATA_DASHBOARD_URL || VITE_SIGMA_REPORT_IDS) {
    listItems.push({
      href: "/data-browser/reports",
      label: "Reports",
      description: "View curated reports to explore your organization's data.",
      icon: ReportsIcon,
    });
  }

  if (datasetsEnabled) {
    listItems.push({
      href: "/data-browser/datasets",
      label: "Datasets",
      description: "Browse collections of data organized by purpose.",
      icon: DatasetIcon,
    });
  }

  if (catalogEnabled) {
    if (tablesTotal) {
      listItems.push({
        href: "/catalog/tables",
        label: "Tables",
        description: `${numFormatter.format(tablesTotal)} tables available`,
        icon: TablesIcon,
      });
    }

    if (columnsTotal) {
      listItems.push({
        href: "/catalog/columns",
        label: "Columns",
        description: `${numFormatter.format(columnsTotal)} columns available`,
        icon: ColumnsIcon,
      });
    }

    if (totalPinned) {
      listItems.push({
        href: "/catalog/pins",
        label: "Pins",
        description: `${numFormatter.format(totalPinned)} pinned ${pluralize("item", totalPinned)}`,
        icon: PinOutlinedIcon,
      });
    }
  }

  listItems.push({
    href: "/files",
    label: "Files",
    description: "Browse personal, workbench and organization files.",
    icon: FilesToolIcon,
  });

  return (
    <Box minHeight="100%" minWidth={620} bgcolor="common.dimmedPageBackground">
      <Container sx={{ p: 3 }} maxWidth="md">
        <ListCard
          loading={loading}
          listStyles={{ p: 1 }}
          placeholder={{ count: 5, height: 80, radius: 2 }}
          sx={{
            borderRadius: 4,
          }}
        >
          {error && (
            <Alert severity="error" variant="outlined">
              Unable to load data
            </Alert>
          )}
          {listItems.map(({ description, href, icon: Icon, label }, i) => (
            <LinkListItem
              key={i}
              icon={<Icon color="#908D9D" height="34px" width="34px" />}
              to={href}
              rootStyles={{
                borderRadius: 2,
                px: 1,
                ".iconContainer": {
                  ml: 1.25,
                  mr: 4,
                },
                ".rowContent": {
                  alignItems: "center",
                  gap: 4,
                  pr: 1,
                  py: 3,
                },
              }}
            >
              <ListItemText
                sx={{ my: "unset" }}
                primary={label}
                primaryTypographyProps={{ fontWeight: 500 }}
                secondary={description}
              />
              <ListItemIcon sx={{ minWidth: "unset" }}>
                <ArrowForwardIos sx={{ opacity: "30%", width: 30 }} />
              </ListItemIcon>
            </LinkListItem>
          ))}
        </ListCard>
      </Container>
    </Box>
  );
};

export default DataBrowserList;
