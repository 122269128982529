import { useFetcher } from "~/hooks/useFetcher";

const { VITE_CATALOG_API } = import.meta.env;

interface SnowflakeSchema {
  name: string;
  comment: string;
}

const useSnowflakeSchemas = () => {
  return useFetcher<SnowflakeSchema[]>(`${VITE_CATALOG_API}/schemas`);
};

export default useSnowflakeSchemas;
