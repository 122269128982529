import { format } from "date-fns";
import pluralize from "pluralize";
import prettyBytes from "pretty-bytes";
import { Link as RouterLink } from "react-router-dom";
import { Box, Card, Link, Skeleton, Stack, styled, Typography as T } from "@mui/material";
import { AwsIcon, CardIllustration } from "~/assets/icons";
import CT from "~/components/ClampedTypography";
import useStatistics from "datasets/hooks/useStatistics";
import Dataset from "datasets/types/interfaces/dataset";
import { getAccentColor } from "datasets/utils/color";

const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);
  return format(date, "MMM dd, yyyy");
};

const buildTableSizesLabel = (tables: number, size?: number) => {
  let strLabel = "";

  strLabel += `${tables} ${pluralize("table", tables)}`;

  if (size) {
    strLabel += ` · ${prettyBytes(size)}`;
  }

  return strLabel;
};

const StyledCard = styled(Card)(() => ({
  borderRadius: 10,
  boxShadow: "none",
  display: "flex",
  flex: 1,
  height: 390,
  overflow: "visible",
  padding: 32,
  paddingTop: 96,
  position: "relative",
  transition: "all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)",
  zIndex: 1,
  "&:hover": {
    transform: "scale(1.02)",
  },
  "&:after": {
    borderRadius: "inherit",
    boxShadow: "0px 1px 4px 0px #D6D6D8",
    content: "''",
    height: "100%",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    transition: "all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: -1,
  },
  "&:hover:after": {
    boxShadow: "0px 1px 1px 0px #D6D6D885, 0px 1px 10px 0px #D6D6D8",
  },
}));

const DatasetCard = ({
  accentColor,
  cloud_providers,
  description,
  id,
  table_count,
  title,
  updated_at,
}: Dataset) => {
  const { data: stats, isLoading } = useStatistics(id);

  return (
    <Link
      to={`/data-browser/datasets/${id}`}
      component={RouterLink}
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1 1 auto",
      }}
    >
      <StyledCard>
        <Box
          sx={{
            backgroundColor: getAccentColor(accentColor),
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
            height: 40,
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <Box position="absolute" height="inherit" top={6} left={0} right={0}>
            <CardIllustration color="white" width={157} height={48} />
          </Box>
        </Box>
        <Stack gap={2} justifyContent="space-between" height="100%" width="100%">
          <Stack gap={1}>
            {isLoading ? (
              <Skeleton variant="text" height={21} width={120} />
            ) : (
              <T
                variant="caption"
                textTransform="capitalize"
                lineHeight="21px"
                sx={{ opacity: 0.7 }}
                fontWeight="500"
              >
                {buildTableSizesLabel(table_count, stats?.table_total_bytes)}
              </T>
            )}
            <CT
              component="h2"
              fontSize={24}
              lineHeight="28px"
              fontWeight={700}
              sx={{ WebkitLineClamp: 2 }}
            >
              {title}
            </CT>
          </Stack>
          <Stack gap={2} flex="1" justifyContent="space-between">
            <CT variant="body2" sx={{ opacity: 0.7, WebkitLineClamp: 6, lineHeight: "21px" }}>
              {description}
            </CT>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {updated_at && (
                <T variant="body2" sx={{ opacity: 0.7, flex: "1 1 0" }}>
                  Updated {formatDate(updated_at)}
                </T>
              )}
              {cloud_providers[0] === "aws" && <AwsIcon height={23} />}
            </Box>
          </Stack>
        </Stack>
      </StyledCard>
    </Link>
  );
};

export default DatasetCard;
