import { useRef } from "react";
import { Box, styled, SxProps, Typography as T, TypographyProps } from "@mui/material";
import PendingIndicator from "~/components/PendingIndicator";

const StyledTypography = styled(T)(({ theme }) => ({
  padding: 4,
  borderRadius: 4,
  textAlign: "center",
  whiteSpace: "pre-wrap",
  display: "inline-block",
  position: "relative",
  transition: "background-color 0.3s ease",
  "&:hover:not(:focus)": {
    cursor: "pointer",
    backgroundColor: theme.palette.grey[200],
  },
  "&:focus-visible": {
    outline: `2px solid ${theme.palette.grey[200]}`,
  },
  "&:empty": {
    textAlign: "left",
    "&::before": {
      textAlign: "center",
      opacity: 0.5,
    },
  },
})) as typeof T;

interface EditableTextProps {
  handleBlur: (val: string) => void;
  loading?: boolean;
  placeholder?: string;
  typographyProps?: TypographyProps;
  typographySx?: SxProps;
  value: string;
}

const EditableText = ({
  handleBlur,
  loading,
  placeholder = "",
  typographyProps,
  typographySx,
  value,
}: EditableTextProps) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const isFocused = textRef.current === document.activeElement;
  return (
    <Box maxWidth={700}>
      <StyledTypography
        ref={textRef}
        component="span"
        {...typographyProps}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const val = e.target.textContent?.trim() ?? "";
          handleBlur(val);
        }}
        onKeyDown={(e) => {
          // Handle enter press as blur/submit
          if (e.key === "Enter") {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
        sx={{
          "&:empty": {
            "&::before": {
              content: `"${placeholder}"`,
            },
          },
          ...typographySx,
        }}
      >
        {value}
        {!isFocused && !!value && (
          <Box component="span" height={10} position="relative">
            {loading && <PendingIndicator />}
          </Box>
        )}
      </StyledTypography>
    </Box>
  );
};

export default EditableText;
