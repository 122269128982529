import { useFetcher } from "~/hooks/useFetcher";
import DatasetStats from "datasets/types/interfaces/stats";

const { VITE_CATALOG_API } = import.meta.env;

const useStatistics = (id?: string) => {
  return useFetcher<DatasetStats>(id ? `${VITE_CATALOG_API}/datasets/${id}/statistics` : null);
};

export default useStatistics;
