import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  List,
  ListItem,
  listItemSecondaryActionClasses,
  ListItemText,
  MenuItem,
  menuItemClasses,
  Skeleton,
  Stack,
  styled,
  Typography as T,
  useTheme,
} from "@mui/material";
import ActionsMenu from "~/components/ActionsMenu";
import CT from "~/components/ClampedTypography";
import LimitedPagination from "~/components/LimitedPagination";
import useDataset from "datasets/hooks/useDataset";
import useDatasetTables, { PAGE_SIZE } from "datasets/hooks/useDatasetTables";
import DatasetTableResponse from "datasets/types/interfaces/table";

const Skeletons = () => (
  <>
    {Array.from({ length: 6 }).map((_, i) => (
      <Skeleton key={i} variant="rounded" height={90} sx={{ "&:not(:first-of-type)": { mt: 1 } }} />
    ))}
  </>
);

const StyledLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: "none",
  fontWeight: 600,
  color: theme.palette.text.link,
  "&:visited": {
    color: theme.palette.text.visited,
  },
  "&:hover": {
    textDecoration: "underline",
  },
}));

// TODO: get a better example for the empty placeholder
const EmptyTableList = () => {
  return (
    <Stack alignItems="center" gap={3} mt={12}>
      <T
        component="h2"
        fontSize={24}
        fontWeight={500}
        sx={{
          lineHeight: "32px",
          opacity: 0.4,
          textAlign: "center",
        }}
      >
        This dataset has no tables.
        <br />
        Add a table below to get started
      </T>
      <Button component={RouterLink} to="new" color="primary" variant="contained">
        Add Table
      </Button>
    </Stack>
  );
};

const TableItem = ({ table }: { table: DatasetTableResponse }) => {
  const theme = useTheme();
  const [showActions, setShowActions] = useState(false);

  const handleLineage = () => {
    setShowActions(false);
    // TODO: add endpoint / action during RWB-1638
  };

  const handleCode = () => {
    setShowActions(false);
    // TODO: add endpoint / action during RWB-1638
  };

  const handleRemove = () => {
    setShowActions(false);
    // TODO: add endpoint / action during RWB-1638
  };

  return (
    <ListItem
      disablePadding
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      secondaryAction={
        <ActionsMenu
          visible={showActions}
          placement="bottom-end"
          menuItems={[
            <MenuItem key="lineage" onClick={handleLineage}>
              View Lineage
            </MenuItem>,
            <MenuItem key="copy-code" onClick={handleCode}>
              Copy Code
            </MenuItem>,
            <MenuItem
              key="remove"
              sx={{
                color: "attention.main",
                [`&.${menuItemClasses.root}:hover`]: {
                  color: "common.white",
                  bgcolor: "attention.main",
                },
              }}
              onClick={handleRemove}
            >
              Remove
            </MenuItem>,
          ]}
        />
      }
      sx={{
        py: 3,
        "&:not(:last-child)": { borderBottom: `1px solid ${theme.palette.grey[300]}` },
        [`& .${listItemSecondaryActionClasses.root}`]: {
          right: 0,
          top: 24,
          transform: "unset",
        },
        "&:first-of-type": {
          pt: 0,

          [`.${listItemSecondaryActionClasses.root}`]: {
            top: 0,
          },
        },
      }}
    >
      <ListItemText
        primary={<StyledLink to={table.fullyQualifiedName}>{table.name}</StyledLink>}
        primaryTypographyProps={{ color: "text.link", fontWeight: 600 }}
        secondary={
          <CT component="span" variant="body2" sx={{ color: "text.secondary", WebkitLineClamp: 4 }}>
            {table.description.trim() ? table.description : "No description"}
          </CT>
        }
        secondaryTypographyProps={{
          fontStyle: table.description.trim() ? "normal" : "italic",
        }}
        sx={{ display: "flex", flexDirection: "column", gap: 1.5, my: 0 }}
      />
    </ListItem>
  );
};

const DatasetTableList = () => {
  const theme = useTheme();
  const { data: dataset } = useDataset();
  const { data: tables, isLoading } = useDatasetTables();
  const showEmpty = !isLoading && tables?.length === 0;

  if (showEmpty) {
    return <EmptyTableList />;
  }

  return (
    <>
      <Box display="flex">
        <Button
          component={RouterLink}
          to="new"
          disabled={isLoading}
          color="secondary"
          variant="contained"
          sx={{
            backgroundColor: theme.palette.grey[100],
          }}
        >
          Add Table
        </Button>
      </Box>
      <List disablePadding sx={{ mt: 3 }}>
        {isLoading ? (
          <Skeletons />
        ) : (
          tables?.map((table) => <TableItem key={table.fullyQualifiedName} table={table} />)
        )}
      </List>
      <LimitedPagination
        total={dataset?.table_count ?? 0}
        defaultPageSize={PAGE_SIZE}
        pageSizeOptions={[10, 20, 50, 100]}
      />
    </>
  );
};

export default DatasetTableList;
