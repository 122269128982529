import { Link as RouterLink, Navigate } from "react-router-dom";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Alert, Box, Container, IconButton, Skeleton, Stack } from "@mui/material";
import useAppHeader from "~/contexts/header";
import { useDataBrowserReports } from "~/hooks/useDataBrowserReports";
import { useDocumentTitle } from "~/hooks/useDocumentTitle";
import { TitleBreadcrumbs } from "~/components/AppHeader";
import CardGrid from "~/components/CardGrid";
import DataBrowserTile from "~/components/DataBrowserTile";
import PageHeader from "~/components/PageHeader";

const { VITE_DATA_DASHBOARD_URL, VITE_SIGMA_REPORT_IDS } = import.meta.env;

const title = "Reports";

const Loading = () => (
  <>
    {Array.from({ length: 4 }).map((_, i) => (
      <Skeleton key={i} variant="rounded" height={175} />
    ))}
  </>
);

const Reports = () => {
  useDocumentTitle(title);
  useAppHeader({
    title: (
      <TitleBreadcrumbs
        crumbs={[
          { title: "Data Browser", href: "/data-browser" },
          { title, href: location.pathname },
        ]}
      />
    ),
  });
  const { reportTiles, loading, error } = useDataBrowserReports();

  if (!VITE_SIGMA_REPORT_IDS && !VITE_DATA_DASHBOARD_URL) {
    return <Navigate to="/404" replace />;
  }

  return (
    <Box bgcolor="common.dimmedPageBackground" minHeight="100%">
      <Container sx={{ pt: 2, pb: 3 }}>
        <Stack gap={2}>
          <Box display="flex" alignItems="center" gap={0.75}>
            <IconButton
              aria-label="Back to Data Browser"
              component={RouterLink}
              to="/data-browser"
              sx={{ borderRadius: "50%", height: 40, width: 40 }}
            >
              <ArrowBackIosNew sx={{ width: 20, height: "auto" }} />
            </IconButton>
            <PageHeader lineHeight={1.8}>Reports</PageHeader>
          </Box>
          {error && (
            <Alert severity="error" variant="outlined">
              Unable to load data
            </Alert>
          )}
          <CardGrid>
            {loading && !error ? (
              <Loading />
            ) : (
              reportTiles.map((report) => <DataBrowserTile key={report.label} {...report} />)
            )}
          </CardGrid>
        </Stack>
      </Container>
    </Box>
  );
};

export default Reports;
