import { RouteObject, Outlet } from "react-router-dom";
import { useSafeRoutes } from "~/hooks/useSafeRoutes";
import Reports from "~/pages/Reports";
import DataBrowserList from "~/components/DataBrowserList";
import { ProtectedAppRoute } from "~/components/ProtectedAppRoute";
import DatasetDetails from "datasets/pages/DatasetDetails";
import Datasets from "datasets/pages/Datasets";

const routeDefinitions: RouteObject[] = [
  {
    index: true,
    element: <DataBrowserList />,
  },
  {
    path: "datasets",
    element: (
      <ProtectedAppRoute permission="list:catalog-dataset">
        <Datasets />
      </ProtectedAppRoute>
    ),
  },
  {
    path: "datasets/:datasetId/*",
    element: (
      <ProtectedAppRoute permission="read:catalog-dataset">
        <DatasetDetails />
      </ProtectedAppRoute>
    ),
  },
  { path: "reports", element: <Reports /> },
];

const DataBrowser = () => {
  const routes = useSafeRoutes(routeDefinitions);

  return (
    <>
      {routes}
      <Outlet />
    </>
  );
};

export default DataBrowser;
