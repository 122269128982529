import { RouteObject, useRoutes } from "react-router-dom";
import RouterError from "~/components/RouterError";

/**
 * Helper to give each route in a list of sub-routes the same error boundary/fallback
 * @param routeDefinitions
 */
export const useSafeRoutes = (routeDefinitions: RouteObject[]) => {
  return useRoutes(
    routeDefinitions.map((def) => ({
      ...def,
      // ID is necessary for useRouteError in RouterError
      id: def.path,
      errorElement: <RouterError />,
    }))
  );
};
