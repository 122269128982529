import { format } from "date-fns";
import pluralize from "pluralize";
import prettyBytes from "pretty-bytes";
import { Link as RouterLink } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import { TabPanel } from "@mui/lab";
import {
  Box,
  Card,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  Skeleton,
  Stack,
  styled,
  Typography as T,
  useTheme,
} from "@mui/material";
import { AwsIcon, TablesIcon } from "~/assets/icons";
import EditableText from "~/components/EditableText";
import FlatCard from "~/components/FlatCard";
import useErrorToast from "~/contexts/error-toast";
import rumClient from "~/utils/monitoring";
import useDataset from "datasets/hooks/useDataset";
import useStatistics from "datasets/hooks/useStatistics";
import { getAccentColor } from "datasets/utils/color";

const { VITE_CATALOG_API } = import.meta.env;

const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);
  return format(date, "MMM dd, yyyy");
};

const Skeletons = () => (
  <Stack gap={1}>
    {Array.from({ length: 6 }).map((_, i) => (
      <Skeleton key={i} variant="text" height={35} />
    ))}
  </Stack>
);

const StyledCard = styled(Card)(() => ({
  borderRadius: 10,
  boxShadow: "none",
  overflow: "visible",
  position: "relative",
  transition: "all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)",
  zIndex: 1,
  "&:hover": {
    transform: "scale(1.02)",
  },
  "&:after": {
    borderRadius: "inherit",
    boxShadow: "0px 1px 4px 0px #D6D6D8",
    content: "''",
    height: "100%",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    transition: "all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)",
    zIndex: -1,
  },
  "&:hover:after": {
    boxShadow: "0px 1px 1px 0px #D6D6D885, 0px 1px 10px 0px #D6D6D8",
  },
}));

const DatasetOverview = () => {
  const { showError } = useErrorToast();
  const theme = useTheme();
  const { data: dataset, isLoading, mutate } = useDataset();
  const { data: stats, isLoading: isLoadingStats } = useStatistics(dataset?.id);
  const hasTables = dataset && dataset.table_count > 0;

  const handleUpdate = async (update: { title?: string; description?: string }) => {
    await mutate(
      async () => {
        try {
          const resp = await fetch(`${VITE_CATALOG_API}/datasets/${dataset?.id}`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(update),
          });

          if (!resp.ok) {
            throw new Error("Unable to update dataset");
          }

          return await resp.json();
        } catch (e: any) {
          console.error(e);
          rumClient?.recordError(e);
          showError(e.message);
        }
      },
      { optimisticData: (data) => ({ ...data!, ...update }) }
    );
  };

  return (
    <TabPanel value="overview">
      <Container maxWidth="lg" sx={{ minHeight: "100%", py: 12.5 }}>
        <Box display="flex" gap={13}>
          <Stack flexGrow={1} gap={2}>
            {isLoading && (
              <>
                <Skeleton variant="rounded" height="40px" width="200px" />
                <Skeletons />
              </>
            )}
            {dataset && (
              <>
                <T component="h1">
                  <EditableText
                    value={dataset.title}
                    placeholder="Untitled"
                    typographySx={{
                      color: getAccentColor(dataset.accentColor),
                      fontSize: 40,
                      fontWeight: 700,
                      lineHeight: 1,
                      textAlign: "start",
                    }}
                    handleBlur={(val) => {
                      if (val !== dataset.title) {
                        handleUpdate({ title: val });
                      }
                    }}
                  />
                </T>

                <EditableText
                  value={dataset.description}
                  placeholder="Description"
                  typographySx={{
                    fontSize: 22,
                    lineHeight: "35px",
                    textAlign: "start",
                  }}
                  handleBlur={(val) => {
                    if (val !== dataset.description) {
                      handleUpdate({ description: val });
                    }
                  }}
                />
              </>
            )}
          </Stack>
          <Stack flex="0 0 440px" gap={2} alignItems="center">
            <Stack gap={2} width="100%">
              <FlatCard
                sx={{
                  border: "1px solid #dadada",
                  borderRadius: 1.25,
                  px: 1,
                  py: 1.5,
                  width: "100%",
                }}
              >
                <Box display="flex" flex={1} lineHeight="22px">
                  <Box display="flex" flex={1} alignItems="center" justifyContent="center">
                    {isLoading ? (
                      <Skeleton variant="rounded" height={27} width={60} />
                    ) : (
                      dataset?.cloud_providers[0].includes("aws") && <AwsIcon width={36} />
                    )}
                  </Box>
                  <Divider sx={{ my: "unset" }} orientation="vertical" variant="middle" flexItem />
                  <Box display="flex" flex={1} justifyContent="center" p={1.5}>
                    {isLoading || isLoadingStats ? (
                      <Skeleton variant="text" height={27} width={100} />
                    ) : (
                      <T color="inherit" fontSize={18} fontWeight={600}>
                        {prettyBytes(stats?.table_total_bytes ?? 0)}
                      </T>
                    )}
                  </Box>
                  <Divider sx={{ my: "unset" }} orientation="vertical" variant="middle" flexItem />
                  <Stack justifyContent="center" alignItems="center" textAlign="center" flex={1}>
                    {isLoading ? (
                      <Skeleton variant="text" height={22} width={90} />
                    ) : (
                      dataset?.updated_at && (
                        <>
                          <T color="inherit" fontSize={12} lineHeight={1.25}>
                            Updated
                          </T>
                          <T color="inherit" fontSize={18} fontWeight={600} lineHeight={1.25}>
                            {formatDate(dataset.updated_at)}
                          </T>
                        </>
                      )
                    )}
                  </Stack>
                </Box>
              </FlatCard>

              <List disablePadding>
                <ListItem disablePadding>
                  <Link
                    to={
                      hasTables
                        ? `/data-browser/datasets/${dataset?.id}/tables`
                        : `/data-browser/datasets/${dataset?.id}/tables/new`
                    }
                    component={RouterLink}
                    sx={{ textDecoration: "unset", width: "100%" }}
                  >
                    <StyledCard>
                      <Box display="flex" alignItems="center" gap={3} px={3} py={2.5}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          bgcolor={
                            !dataset
                              ? theme.palette.grey[300]
                              : getAccentColor(dataset?.accentColor)
                          }
                          borderRadius={56}
                          width={56}
                          height={56}
                        >
                          <TablesIcon width={28} color={theme.palette.common.white} />
                        </Box>
                        <Stack flex={1} gap={1}>
                          {isLoading ? (
                            <>
                              <Skeleton variant="rounded" height={20} width={120} />
                              <Skeleton variant="text" height={20} width={160} />
                            </>
                          ) : (
                            <T
                              fontSize={18}
                              fontWeight={500}
                              lineHeight="19px"
                              color={
                                hasTables
                                  ? theme.palette.text.primary
                                  : getAccentColor(dataset?.accentColor)
                              }
                            >
                              {hasTables ? "Tables" : "Add a Table"}
                            </T>
                          )}
                          {hasTables && (
                            <T variant="body2" color="#575759">
                              {`${dataset?.table_count} ${pluralize(
                                "tables",
                                dataset?.table_count
                              )} available`}
                            </T>
                          )}
                        </Stack>
                        <ArrowForwardIos sx={{ fontSize: "14px" }} />
                      </Box>
                    </StyledCard>
                  </Link>
                </ListItem>
              </List>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </TabPanel>
  );
};

export default DatasetOverview;
