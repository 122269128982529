import { useMemo } from "react";
import { concat, mergeDeepWith, splitEvery } from "ramda";
import useLocalStorage from "use-local-storage-state";
import queryString from "query-string";
import { useDebounce } from "~/hooks/useDebounce";
import { TableEntity, VariableEntity } from "~/types/interfaces/catalogResponse";
import useFilterParams from "catalog/hooks/useFilterParams";
import { buildFilterMatrix } from "catalog/utils/buildFilterMatrix";
import useSWRInfinite, { SWRInfiniteKeyLoader, SWRInfiniteResponse } from "swr/infinite";
import useCatalogEnabled from "catalog/hooks/useCatalogEnabled";

interface PinnedEntities {
  [id: string]: TableEntity | VariableEntity;
}

const CHUNK_SIZE = 30;
const LOCAL_STORAGE_KEY = "sc-pinned-entities";
const { VITE_CATALOG_API } = import.meta.env;

const useChunkedFetcher = (
  getKey: SWRInfiniteKeyLoader,
  numChunks: number
): SWRInfiniteResponse => {
  const catalogEnabled = useCatalogEnabled();
  return useSWRInfinite(
    catalogEnabled ? getKey : () => null,
    (url: string) => fetch(url).then((res) => res.json()),
    {
      parallel: true,
      initialSize: numChunks,
      keepPreviousData: true,
    }
  );
};

const usePinnedEntities = (): {
  entities: PinnedEntities;
  total: number;
  loading: boolean;
  isPinned: (urn: string) => boolean;
  addEntities: (urns: string[]) => void;
  removeEntities: (urns: string[]) => void;
} => {
  const [params] = useFilterParams();
  const { q: search, ttags = [], ctags = [] } = params;
  const debouncedSearch = useDebounce(search);
  const [pinnedEntityFqns, setPinnedEntityFqns] = useLocalStorage<string[]>(LOCAL_STORAGE_KEY, {
    defaultValue: [],
  });

  const baseUrl = `${VITE_CATALOG_API}/search/query`;

  const makeQueryString = (index: string, filters: any) =>
    queryString.stringify({
      index: index,
      q: debouncedSearch || "*",
      query_filter: JSON.stringify(filters),
      // Use ES max limit since we'll paginate client side to allow for cross-page selection
      size: 10000,
    });

  const keyGenerator =
    (baseUrl: string, searchIndex: string, tags: string[], chunks: string[][]) =>
    (chunkIndex: number): string | null => {
      if (!chunks.length || !chunks[chunkIndex]) {
        return null;
      }

      const filterMatrix = buildFilterMatrix(tags, true);

      // Combine tag filters with pinned entity FQN term match
      const filters = mergeDeepWith(concat, filterMatrix, {
        query: {
          bool: {
            should: chunks[chunkIndex].map((fqn) => ({
              term: { fullyQualifiedName: fqn },
            })),
            minimum_should_match: 1,
          },
        },
      });

      const qs = makeQueryString(searchIndex, filters);
      return `${baseUrl}?${qs}`;
    };

  // Split pinned entity FQN list into chunks to avoid 414/431 error code response
  // due to a single request url becoming too lengthy after a certain point when sent together
  const chunks = splitEvery(CHUNK_SIZE, pinnedEntityFqns);

  const {
    data: tableData,
    isLoading: isLoadingTables,
    mutate: mutateTables,
  } = useChunkedFetcher(
    keyGenerator(baseUrl, "catalog-api-table_search_index", ttags as string[], chunks),
    chunks.length
  );

  const {
    data: columnData,
    isLoading: isLoadingColumns,
    mutate: mutateColumns,
  } = useChunkedFetcher(
    keyGenerator(baseUrl, "catalog-api-column_search_index", ctags as string[], chunks),
    chunks.length
  );

  const tableResults = tableData?.flatMap(({ hits }) => hits?.hits ?? []);
  const columnResults = columnData?.flatMap(({ hits }) => hits?.hits ?? []);

  const tableTotal = tableResults?.length ?? 0;
  const columnTotal = columnResults?.length ?? 0;
  const isLoading = isLoadingTables || isLoadingColumns;
  const showEntities = tableData && columnData;

  const pinnedEntityMap = useMemo(() => {
    return [...(tableResults || []), ...(columnResults || [])].reduce(
      (acc: PinnedEntities, { _source }) => {
        return { ...acc, [_source.fullyQualifiedName]: _source };
      },
      {}
    );
  }, [tableResults, columnResults]);

  return {
    entities: showEntities ? pinnedEntityMap : {},
    total: showEntities ? tableTotal + columnTotal : 0,
    loading: isLoading,
    isPinned: (fqn) => pinnedEntityFqns.includes(fqn),
    addEntities: (urns) => {
      // Add new to end of list
      const combined = [...(pinnedEntityFqns ?? []), ...urns];
      // Ensure uniqueness
      const uniqueFqns = [...new Set(combined)];
      setPinnedEntityFqns(uniqueFqns);
      // setTimeout to ensure mutation happens after state update
      setTimeout(mutateTables);
      setTimeout(mutateColumns);
    },
    removeEntities: (urns) =>
      setPinnedEntityFqns([...pinnedEntityFqns.filter((fqn) => !urns.includes(fqn))]),
  };
};

export default usePinnedEntities;
