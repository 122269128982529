import { useParams } from "react-router-dom";
import queryString from "query-string";
import { useQueryParams, withDefault, NumberParam } from "use-query-params";
import { useFetcher } from "~/hooks/useFetcher";
import DatasetTableResponse from "datasets/types/interfaces/table";

const { VITE_CATALOG_API } = import.meta.env;

export const PAGE_SIZE = 10;

const useDatasetTables = (id?: string, size?: number) => {
  const { datasetId = id } = useParams();
  const [query] = useQueryParams({
    perPage: withDefault(NumberParam, size ?? PAGE_SIZE),
    page: withDefault(NumberParam, 0),
  });

  const { page, perPage } = query;

  const qs = queryString.stringify({
    limit: perPage,
    offset: page ? (page - 1) * perPage : 0,
  });

  return useFetcher<DatasetTableResponse[]>(
    datasetId ? `${VITE_CATALOG_API}/tables/dataset/${datasetId}?${qs}` : null
  );
};

export default useDatasetTables;
