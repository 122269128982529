import { Outlet, RouteObject } from "react-router-dom";
import { TabPanel } from "@mui/lab";
import { Container } from "@mui/material";
import { useSafeRoutes } from "~/hooks/useSafeRoutes";
import AddTable from "datasets/components/AddTable";
import DatasetTableList from "datasets/components/DatasetTableList";
import DatasetTable from "datasets/components/DatasetTable";

const routeDefinitions: RouteObject[] = [
  { index: true, element: <DatasetTableList /> },
  { path: ":tableFQN", element: <DatasetTable /> },
  { path: "new", element: <AddTable /> },
];

const DatasetTables = () => {
  const routes = useSafeRoutes(routeDefinitions);

  return (
    <TabPanel value="tables" sx={{ p: 0 }}>
      <Container sx={{ p: 3 }}>
        {routes}
        <Outlet />
      </Container>
    </TabPanel>
  );
};

export default DatasetTables;
