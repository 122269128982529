import { useMemo, useState, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { KeyedMutator } from "swr";
import {
  Avatar,
  avatarClasses,
  AvatarGroup,
  Box,
  Stack,
  Link,
  MenuItem,
  menuItemClasses,
  Typography as T,
} from "@mui/material";
import { formatRelative } from "date-fns";
import StopIcon from "@mui/icons-material/Stop";
import Project from "rwb/types/interfaces/project";
import { EnvironmentsStatus } from "rwb/pages/projects/components/EnvironmentsStatus";
import useUser from "~/contexts/user";
import ActionsMenu from "~/components/ActionsMenu";
import CT from "~/components/ClampedTypography";
import InteractiveCard from "~/components/InteractiveCard";
import { DeleteProjectModal } from "rwb/components/DeleteProjectModal";
import useProjectAccess from "rwb/hooks/useProjectAccess";
import ProjectPrivilege from "rwb/pages/project/types/enums/privilege";
import EnvironmentStatus from "rwb/pages/project/types/enums/environmentStatus";
import { ProjectsResponse } from "rwb/hooks/useProjects";
import LeaveProjectModal from "rwb/pages/project/components/LeaveProjectModal";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { DEFAULT_SORT } from "rwb/pages/projects/types/sort";

const { VITE_RWB_API } = import.meta.env;

type ProjectCardProps = {
  data: Project;
  mutateProjects: KeyedMutator<ProjectsResponse>;
};

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);
  const relativeFormat = formatRelative(date, new Date());
  return relativeFormat.split(" at ")[0];
};

const ProjectCard = ({ data, mutateProjects }: ProjectCardProps) => {
  const user = useUser();
  const [sort] = useQueryParam("sort_field", withDefault(StringParam, DEFAULT_SORT));
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [leaveModalOpen, setLeaveModalOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const { id, title, environments, last_accessed_at } = data;
  const canDelete = useProjectAccess(ProjectPrivilege.DELETE_PROJECT, data);
  const canManageCompute =
    useProjectAccess(ProjectPrivilege.MANAGE_COMPUTE, data) &&
    user?.permissions?.includes("manage:compute-environments");

  const handleStopEnvs = useCallback(async () => {
    const resp = await fetch(`${VITE_RWB_API}/environments?project_id=${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ state: EnvironmentStatus.STOPPED, project_id: data.id }),
    });

    if (resp.ok) {
      await mutateProjects();
    }
  }, [data, id, mutateProjects]);

  const formattedTimestamp = useMemo(() => {
    if (sort === "created_at" && data.created_at) {
      return `Created ${formatTimestamp(data.created_at)}`;
    }
    if (sort === "updated_at" && data.updated_at) {
      return `Updated ${formatTimestamp(data.updated_at)}`;
    }
    if (data.last_accessed_at) {
      return `Used ${formatTimestamp(data.last_accessed_at)}`;
    }
    return null;
  }, [data, sort]);

  const menuItems = useMemo(() => {
    const result = [];
    if (canManageCompute) {
      const hasRunningEnvs = data.environments?.some(
        (env) => env.status === EnvironmentStatus.RUNNING
      );

      if (hasRunningEnvs) {
        result.push(
          <MenuItem key="stop-envs" onClick={handleStopEnvs}>
            <StopIcon fontSize="small" sx={{ mr: 1 }} />
            Stop All Environments
          </MenuItem>
        );
      }
    }
    result.push(
      <MenuItem
        key="delete"
        sx={{
          color: "attention.main",
          [`&.${menuItemClasses.root}:hover`]: {
            color: "common.white",
            bgcolor: "attention.main",
          },
        }}
        onClick={() => {
          if (canDelete) {
            setDeleteModalOpen(true);
          } else {
            setLeaveModalOpen(true);
          }
        }}
      >
        {canDelete ? "Delete" : "Leave"}
      </MenuItem>
    );
    return result;
  }, [data, canDelete, canManageCompute, handleStopEnvs]);

  return (
    <>
      <Link
        data-testid="project-card"
        to={`/workbenches/${id}`}
        component={RouterLink}
        underline="none"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "1 1 auto",
        }}
      >
        <InteractiveCard
          onMouseEnter={() => {
            if (menuItems.length) {
              setShowActions(true);
            }
          }}
          onMouseLeave={() => {
            if (menuItems.length) {
              setShowActions(false);
            }
          }}
          sx={{ height: 205, p: 3 }}
        >
          <Stack justifyContent="space-between" gap={1} flex={1}>
            {/**
             * Meatball Menu
             */}
            <Box position="absolute" right={12} top={12}>
              <ActionsMenu disablePortal visible={showActions} menuItems={menuItems} />
            </Box>
            <Stack gap={1.25}>
              {/**
               * Title
               */}
              <CT
                className="title"
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 500,
                  fontSize: 16,
                  WebkitLineClamp: 4,
                  maxWidth: "calc(100% - 24px)",
                }}
              >
                {title}
              </CT>
              <T variant="caption" sx={{ opacity: 0.7, fontSize: 14, lineHeight: 1.2 }}>
                {formattedTimestamp}
              </T>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
              {/**
               * Environments
               */}
              <Box display="flex" alignItems="center" minWidth={0}>
                {canManageCompute && (
                  <EnvironmentsStatus
                    projectId={id}
                    environments={environments}
                    mutateProjects={mutateProjects}
                  />
                )}
              </Box>
              {/**
               * Collaborators
               */}
              <AvatarGroup
                max={4}
                sx={{
                  width: "fit-content",
                  [`& .${avatarClasses.root}`]: {
                    width: 26,
                    height: 26,
                    whiteSpace: "nowrap",
                    fontSize: 11,
                    fontWeight: 500,
                  },
                }}
              >
                {data.members.map(({ member_identity }) => (
                  <Avatar
                    key={member_identity.email}
                    alt={member_identity.name ?? member_identity.email}
                    src={member_identity.picture}
                  />
                ))}
              </AvatarGroup>
            </Stack>
          </Stack>
        </InteractiveCard>
      </Link>
      <DeleteProjectModal
        project={data}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />
      <LeaveProjectModal
        project={data}
        open={leaveModalOpen}
        onClose={() => setLeaveModalOpen(false)}
      />
    </>
  );
};

export default ProjectCard;
