import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, CardContent, Link, MenuItem, menuItemClasses, Typography as T } from "@mui/material";
import pluralize from "pluralize";
import CT from "~/components/ClampedTypography";
import { CohortIcon } from "~/assets/icons";
import useErrorToast from "~/contexts/error-toast";
import ActionsMenu from "~/components/ActionsMenu";
import InteractiveCard, { IconContainer } from "~/components/InteractiveCard";
import { CohortSummary } from "cohorts/types/interfaces/cohort";
import useTotalParticipantsCount from "cohorts/hooks/useTotalParticipantsCount";
import useCohorts from "cohorts/hooks/useCohorts";
import DeleteCohortModal from "./DeleteCohortModal";
import RenameCohortModal from "./RenameCohortModal";
import { trackEvent } from "~/utils/monitoring";

const numFormatter = Intl.NumberFormat("en", { notation: "compact" });

const CohortListCard = ({ cohort }: { cohort: CohortSummary }) => {
  const { showError } = useErrorToast();
  const { mutate } = useCohorts();
  const { data: total } = useTotalParticipantsCount();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const participantCount = cohort.participant_count ?? total?.participant_count;
  const description = cohort.user_description || cohort.llm_description;

  const handleRename = () => {
    setShowActions(false);
    setRenameModalOpen(true);
  };

  const handleDuplicate = async () => {
    const resp = await fetch(
      `/api/cohort-explorer/cohorts/${cohort!.id}/duplicate?async_query=true`,
      {
        method: "POST",
      }
    );

    if (resp.ok) {
      const body = await resp.json();
      trackEvent("cohort_duplicated", {
        cohort_id: body.id,
        original_cohort_id: cohort!.id,
      });
      mutate();
    } else {
      showError("Unable to duplicate cohort");
    }
  };

  const handleDelete = () => {
    setShowActions(false);
    setDeleteModalOpen(true);
  };

  const menuItems = [
    <MenuItem key="rename" onClick={handleRename}>
      Rename...
    </MenuItem>,
    <MenuItem key="duplicate" onClick={handleDuplicate}>
      Duplicate
    </MenuItem>,
    <MenuItem
      key="delete"
      onClick={handleDelete}
      sx={{
        color: "attention.main",
        [`&.${menuItemClasses.root}:hover`]: {
          color: "common.white",
          bgcolor: "attention.main",
        },
      }}
    >
      Delete
    </MenuItem>,
  ];

  return (
    <>
      <Link
        component={RouterLink}
        underline="none"
        to={`/cohorts/${String(cohort.id)}`}
        sx={{ display: "flex" }}
      >
        <InteractiveCard
          onMouseEnter={() => {
            setShowActions(true);
          }}
          onMouseLeave={() => {
            setShowActions(false);
          }}
        >
          <CardContent sx={{ px: 3, pt: 2.5, gap: 1.25, height: 205 }}>
            <Box display="flex" alignItems="center" flex="0" justifyContent="space-between">
              <IconContainer sx={{ bgcolor: "white", color: "#860DFF" }}>
                <CohortIcon />
              </IconContainer>
              <Box position="absolute" right={12} top={12}>
                <ActionsMenu
                  disablePortal
                  placement="bottom-start"
                  visible={showActions}
                  menuItems={menuItems}
                />
              </Box>
            </Box>

            <CT variant="body1" sx={{ lineHeight: 1.2, fontWeight: 500 }}>
              {cohort.name}
            </CT>
            {description && (
              <CT variant="body2" sx={{ opacity: 0.7 }}>
                {description}
              </CT>
            )}
            {participantCount !== undefined && (
              <T
                variant="body2"
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  opacity: 0.7,
                }}
              >
                {numFormatter.format(participantCount)} {pluralize("participant", participantCount)}
              </T>
            )}
          </CardContent>
        </InteractiveCard>
      </Link>

      <RenameCohortModal
        id={cohort.id}
        name={cohort.name}
        open={renameModalOpen}
        handleClose={() => setRenameModalOpen(false)}
      />

      <DeleteCohortModal
        id={cohort.id}
        name={cohort.name}
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default CohortListCard;
