import pluralize from "pluralize";
import { ComponentProps, FunctionComponent } from "react";
import { DataOverview } from "~/assets/icons";
import { DISPLAY_REPORT } from "~/types/enums/report";
import useUser from "~/contexts/user";
import { useSemanticCatalogEnabled } from "catalog/hooks/useCatalogEnabled";
import useConceptCategories from "catalog/hooks/useConceptCategories";
import ConceptCategory, { DISPLAY_CONCEPT_CATEGORY } from "catalog/types/enums/conceptCategory";
import { DISPLAY_CONCEPT_ENTITY } from "catalog/types/enums/conceptEntity";

const { VITE_DATA_DASHBOARD_URL, VITE_SIGMA_REPORT_IDS } = import.meta.env;

const numFormatter = Intl.NumberFormat("en", { notation: "compact" });

interface TileProps {
  label: string;
  description: string;
  href: string;
  icon?: FunctionComponent<ComponentProps<"svg">>;
  iconColor?: string;
  backgroundColor?: string;
}

const mapSigmaReportIds = (ids: string[]): TileProps[] => {
  const tiles = [];

  for (const id of ids) {
    // Ensure configured ID is proper format
    const [reportType, displayMapping] =
      Object.entries(DISPLAY_REPORT).find(([, mapping]) => mapping.ids.includes(id)) ?? [];

    if (reportType && displayMapping) {
      tiles.push({
        ...displayMapping,
        href: `/reports/${reportType}`,
      });
    }
  }

  return tiles;
};

export const useDataBrowserReports = () => {
  const user = useUser();
  const semanticCatalogEnabled = useSemanticCatalogEnabled();
  const {
    data: conceptCategories,
    loading: loadingConcepts,
    error: conceptsError,
  } = useConceptCategories();

  const loading = !user || loadingConcepts;

  const reportTiles = [];

  if (VITE_DATA_DASHBOARD_URL) {
    reportTiles.push({
      href: "/data-overview",
      label: "Overview",
      description: "Top level summary of organization data",
      icon: DataOverview,
      iconColor: "#860DFF",
      backgroundColor: "#F2E6FF",
    });
  }

  if (VITE_SIGMA_REPORT_IDS) {
    const sigmaReports = mapSigmaReportIds(VITE_SIGMA_REPORT_IDS.split(","));
    reportTiles.push(...sigmaReports);
  }

  if (conceptCategories && semanticCatalogEnabled) {
    conceptCategories.forEach(({ category, counts }) => {
      const categoryDisplay = DISPLAY_CONCEPT_CATEGORY[category as ConceptCategory];
      if (categoryDisplay && counts.length) {
        const formattedCount = counts
          .map(({ type, count }) => {
            return `${numFormatter.format(count)} ${pluralize(
              DISPLAY_CONCEPT_ENTITY[type],
              count
            )}`;
          })
          .join(" · ")
          .toLowerCase();

        reportTiles.push({
          href: `/catalog/concepts/${category}`,
          label: categoryDisplay.title,
          description: formattedCount,
          icon: categoryDisplay.icon,
          iconColor: categoryDisplay.iconColor,
          backgroundColor: categoryDisplay.backgroundColor,
        });
      }
    });
  }

  return { reportTiles, loading, error: conceptsError };
};
