import { useFetcher } from "~/hooks/useFetcher";
import { DomainIconOptions } from "~/types/enums/domain";

export interface DomainFilterOption {
  id: string;
  name: string;
  export_filter_allowed: boolean;
  export_filter_required: boolean;
  export_filter_max_options: number | null;
}

export interface DomainDefinition {
  id: string;
  name: string;
  export: boolean;
  filters: DomainFilterOption[];
  icon?: DomainIconOptions | null;
}

const useDomains = () => {
  return useFetcher<DomainDefinition[]>("/api/cohort-explorer/domains");
};

export default useDomains;
