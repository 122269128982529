import { MouseEvent, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  chipClasses,
  inputClasses,
  List,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  outlinedInputClasses,
  Popover,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography as T,
} from "@mui/material";
import { PlusIcon, SearchIcon } from "~/assets/icons";
import useIngestTypes from "datasets/hooks/useIngestTypes";
import { IngestForm } from "datasets/types/interfaces/ingest";

const StyledTHeaderCell = styled(TableCell)({
  padding: "8px 6px",
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
});

const StyledTBodyCell = styled(TableCell)({
  padding: "16px 6px",
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
});

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  gap: 4,
  padding: 8,
  [`.${inputClasses.inputSizeSmall}`]: {
    height: "unset",
    lineHeight: "20px",
    padding: 0,
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.grey[300],
  },
}));

const TableMetadataDetails = ({ onNextPanel }: { onNextPanel: () => void }) => {
  const { data: ingestTypes } = useIngestTypes();
  const { control } = useFormContext<IngestForm>();

  const [datatypeAnchorEl, setDatatypeAnchorEl] = useState<null | HTMLElement>(null);

  const { fields, update } = useFieldArray({
    control,
    name: "metadata_config.columns",
  });

  const handleDatatypeMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setDatatypeAnchorEl(event.currentTarget);
  };

  const handleDatatypeMenuClose = () => {
    setDatatypeAnchorEl(null);
  };

  return (
    <>
      <Box
        alignItems="center"
        bgcolor="common.dimmedPageBackground"
        borderRadius={1.5}
        display="flex"
        px={1.25}
        py={1}
        width="100%"
      >
        <T variant="body2" fontWeight={500}>
          Metadata information
        </T>
      </Box>

      <Stack gap={0.5} width="100%">
        <Controller
          control={control}
          name="metadata_config.table"
          render={({ field }) => (
            <>
              <T variant="body2" fontWeight={500}>
                Display Name
              </T>
              <StyledOutlinedInput
                {...field}
                fullWidth
                placeholder="Enter a display name for this table..."
                size="small"
              />
            </>
          )}
        />
      </Stack>

      <Stack gap={0.5} width="100%">
        <Controller
          control={control}
          name="metadata_config.table_description"
          render={({ field }) => (
            <>
              <T variant="body2" fontWeight={500}>
                Description
              </T>
              <StyledOutlinedInput
                {...field}
                fullWidth
                multiline
                minRows={2}
                placeholder="Describe this table..."
              />
            </>
          )}
        />
      </Stack>

      <Stack alignItems="flex-start" gap={0.5} width="100%">
        <T variant="body2" fontWeight={500}>
          Table Tags
        </T>
        <Button
          variant="text"
          size="small"
          startIcon={<PlusIcon />}
          sx={{ color: "primary.main", fontSize: 13 }}
        >
          Add
        </Button>
      </Stack>

      <Stack gap={1.25} width="100%">
        <Stack gap={0.5}>
          <T variant="body2" fontWeight={500}>
            Columns
          </T>
          <StyledOutlinedInput
            size="small"
            placeholder="Search by column name..."
            startAdornment={
              <Box display="flex" alignItems="center" justifyContent="center">
                <SearchIcon width={16} />
              </Box>
            }
          />
        </Stack>
        <TableContainer
          sx={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "grey.300",
            borderRadius: 1.5,
          }}
        >
          <Table size="small" sx={{ minWidth: 600, overflowX: "auto" }}>
            <TableHead sx={{ bgcolor: "common.dimmedPageBackground" }}>
              <TableRow>
                <StyledTHeaderCell>Name</StyledTHeaderCell>
                <StyledTHeaderCell>Type</StyledTHeaderCell>
                <StyledTHeaderCell>Description</StyledTHeaderCell>
                <StyledTHeaderCell>Tags</StyledTHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ p: 4 }}>
              {fields.map((column, index) => (
                <TableRow
                  key={column.id}
                  sx={{
                    verticalAlign: "top",
                    "&:last-of-type td": { borderBottom: "unset" },
                  }}
                >
                  <StyledTBodyCell>
                    <Box minHeight={36} display="flex" alignItems="center">
                      <T fontSize={14}>{column.name}</T>
                    </Box>
                  </StyledTBodyCell>
                  <StyledTBodyCell width="15%">
                    <Button
                      fullWidth
                      size="small"
                      variant="outlined"
                      endIcon={<ArrowForwardIos sx={{ transform: "rotate(90deg)", width: 16 }} />}
                      sx={{ minHeight: 36, justifyContent: "space-between" }}
                      onClick={handleDatatypeMenuOpen}
                    >
                      {column.type}
                    </Button>
                    <Popover
                      anchorEl={datatypeAnchorEl}
                      open={!!datatypeAnchorEl}
                      onClose={handleDatatypeMenuClose}
                      PaperProps={{
                        style: {
                          boxShadow:
                            "0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033",
                          maxHeight: 300,
                        },
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <List dense disablePadding>
                        {ingestTypes?.map((t, i) => (
                          <ListItemButton
                            key={i}
                            onClick={() => {
                              update(index, { ...column, type: t });
                            }}
                          >
                            <ListItemText>{t}</ListItemText>
                          </ListItemButton>
                        ))}
                      </List>
                    </Popover>
                  </StyledTBodyCell>
                  <StyledTBodyCell width="45%">
                    <StyledOutlinedInput
                      value={column.description}
                      autoComplete="off"
                      multiline
                      fullWidth
                      placeholder="Describe this column..."
                      sx={{
                        minHeight: 36,
                      }}
                    />
                  </StyledTBodyCell>
                  <StyledTBodyCell width="25%">
                    <Box display="flex" gap={0.75} alignItems="center" minHeight={36}>
                      {column.tags.length > 0 ? (
                        column.tags.map((tag, i) => (
                          <Chip
                            key={i}
                            label={tag}
                            size="small"
                            sx={{ fontSize: 13, [`& .${chipClasses.label}`]: { px: 1.25 } }}
                          />
                        ))
                      ) : (
                        <Button
                          variant="text"
                          size="small"
                          startIcon={<PlusIcon />}
                          sx={{ fontSize: 13, color: "primary.main" }}
                        >
                          Add
                        </Button>
                      )}
                    </Box>
                  </StyledTBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Button variant="contained" onClick={() => onNextPanel()}>
        Next
      </Button>
    </>
  );
};

export default TableMetadataDetails;
