import { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography as T,
} from "@mui/material";
import CloudStorageForm from "datasets/components/CloudStorageForm";
import SnowflakeForm from "datasets/components/SnowflakeForm";

const AddTable = () => {
  const { datasetId } = useParams();
  const [dataLocation, setDataLocation] = useState("cloud");

  return (
    <Stack gap={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <T variant="h6" component="h3" fontWeight={600}>
          Add Table
        </T>
        <IconButton
          aria-label="Back to dataset tables"
          component={RouterLink}
          to={`/data-browser/datasets/${datasetId}/tables`}
          sx={{ height: 40, width: 40 }}
        >
          <Close sx={{ height: "auto", width: 28 }} />
        </IconButton>
      </Box>
      <Stack gap={1}>
        <T variant="body2" fontWeight={500}>
          Where is your data located?
        </T>
        <RadioGroup
          row
          value={dataLocation}
          onChange={(e) => setDataLocation(e.currentTarget.value)}
        >
          <FormControlLabel value="cloud" control={<Radio />} label="Amazon S3" />
          <FormControlLabel value="snowflake" control={<Radio />} label="Snowflake" />
        </RadioGroup>
      </Stack>
      {dataLocation === "cloud" && <CloudStorageForm />}
      {dataLocation === "snowflake" && <SnowflakeForm />}
    </Stack>
  );
};

export default AddTable;
