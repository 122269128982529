import { CheckRounded } from "@mui/icons-material";
import { Box, IconButton, styled, useRadioGroup, useTheme } from "@mui/material";

const ColorCircle = styled(Box)({
  width: 24,
  height: 24,
  borderRadius: "50%",
});

const ColorRadio = ({
  disabled,
  onChange,
  value,
}: {
  disabled?: boolean;
  onChange: (color: string) => void;
  value: string;
}) => {
  const theme = useTheme();
  const radioGroup = useRadioGroup();
  const checked = radioGroup ? radioGroup.value === value : false;

  return (
    <IconButton
      disabled={disabled}
      onClick={() => onChange(value)}
      sx={{
        borderColor: checked ? theme.palette.grey[400] : "transparent",
        borderRadius: 1.5,
        borderStyle: "solid",
        borderWidth: 1,
        p: 0.75,
      }}
    >
      <ColorCircle sx={{ backgroundColor: value }} />
      {checked && (
        <CheckRounded sx={{ color: theme.palette.common.white, position: "absolute", width: 16 }} />
      )}
    </IconButton>
  );
};

export default ColorRadio;
