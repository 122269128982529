import { cloneElement, ReactElement, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DevTool } from "@hookform/devtools";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  inputClasses,
  OutlinedInput,
  Portal,
  RadioGroup,
  Stack,
  Typography as T,
} from "@mui/material";
import ColorRadio from "datasets/components/ColorRadio";
import useDatasets from "datasets/hooks/useDatasets";
import AccentColor from "datasets/types/enums/accent-color";
import Dataset from "datasets/types/interfaces/dataset";

const { DEV, VITE_CATALOG_API } = import.meta.env;

type DatasetForm = { title: string; description: string; accentColor: string };

const CreateForm = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const { mutate } = useDatasets();
  const [error, setError] = useState("");
  const form = useForm<DatasetForm>({
    defaultValues: { title: "", description: "", accentColor: AccentColor.PURPLE },
  });

  const onSubmit = async (data: DatasetForm) => {
    const resp = await fetch(`${VITE_CATALOG_API}/datasets`, {
      method: "POST",
      body: JSON.stringify({
        title: data.title,
        accentColor: data.accentColor,
        description: data.description,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.ok) {
      const datasetResp: Dataset = await resp.json();
      await mutate();
      navigate(`/data-browser/datasets/${datasetResp.id}`);
    } else {
      setError("Unable to create dataset");
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <DialogTitle>
        New Dataset
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" onClose={() => setError("")} sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Stack gap={2}>
          <Controller
            control={form.control}
            name="title"
            rules={{
              required: { value: true, message: "Required" },
            }}
            render={({ field, formState }) => (
              <Stack>
                <FormLabel>
                  <T variant="label" sx={{ lineHeight: "20px", opacity: 1 }}>
                    Title
                  </T>
                </FormLabel>
                <OutlinedInput
                  {...field}
                  disabled={formState.isSubmitting}
                  autoComplete="off"
                  size="small"
                  placeholder="Enter dataset title..."
                  sx={{
                    [`.${inputClasses.inputSizeSmall}`]: {
                      height: "unset",
                      lineHeight: "20px",
                      p: 1,
                    },
                  }}
                />
              </Stack>
            )}
          />
          <Controller
            control={form.control}
            name="accentColor"
            render={({ field, formState }) => (
              <Stack>
                <T variant="label" sx={{ opacity: 1 }}>
                  Accent Color
                </T>
                <RadioGroup
                  {...field}
                  row
                  value={field.value}
                  onChange={(_, val) => field.onChange(val)}
                >
                  {Object.values(AccentColor).map((option) => (
                    <ColorRadio
                      key={option}
                      disabled={formState.isSubmitting}
                      value={option}
                      onChange={field.onChange}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            )}
          />
          <Controller
            control={form.control}
            name="description"
            render={({ field, formState }) => (
              <Stack>
                <T variant="label" sx={{ opacity: 1 }}>
                  Dataset Description
                </T>
                <OutlinedInput
                  {...field}
                  autoComplete="off"
                  multiline
                  rows={5}
                  disabled={formState.isSubmitting}
                  placeholder="Describe this dataset..."
                  sx={{
                    p: 1,
                  }}
                />
              </Stack>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          disabled={form.formState.isSubmitting}
          size="medium"
          color="inherit"
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={!form.formState.isValid}
          loading={form.formState.isSubmitting}
          variant="contained"
          size="medium"
        >
          Create Dataset
        </LoadingButton>
      </DialogActions>
      {DEV && (
        <Portal>
          <DevTool control={form.control} placement="bottom-right" />
        </Portal>
      )}
    </form>
  );
};

const CreateDataset = ({ callToAction }: { callToAction: ReactElement }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const boundCta = cloneElement(callToAction, {
    onClick: () => setOpen(true),
    "data-testid": "new-dataset",
  });

  return (
    <>
      {boundCta}
      <Dialog fullWidth open={open} onClose={handleClose} disableRestoreFocus={DEV}>
        <CreateForm onClose={handleClose} />
      </Dialog>
    </>
  );
};

export default CreateDataset;
