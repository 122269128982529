import CircularProgress from "@mui/material/CircularProgress";

const PendingIndicator = () => (
  <CircularProgress
    variant="indeterminate"
    disableShrink
    sx={{
      right: -14,
      top: "calc(50% - 4px)",
      color: "grey.700",
      animationDuration: "550ms",
      position: "absolute",
    }}
    size={10}
    thickness={4}
  />
);

export default PendingIndicator;
